import React from 'react'
import { Tooltip } from '@mui/material'

function CustomToolTip({ tooltipFontSize, ...other }) {
    return (
        <Tooltip
            {...other}
            componentsProps={{
                tooltip: {
                    sx: {
                        fontSize: tooltipFontSize || '1rem', // Fallback to '1rem' if tooltipFontSize is not provided
                        backgroundColor: 'rgba(135, 206, 250, 1)', // Faint blue background color
                        color: '#000000', // Black text color
                    },
                },
            }}
        />
    )
}

export default CustomToolTip