/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCandidate = /* GraphQL */ `
  mutation CreateCandidate(
    $input: CreateCandidateInput!
    $condition: ModelCandidateConditionInput
  ) {
    createCandidate(input: $input, condition: $condition) {
      id
      interviewqas {
        items {
          id
          candidateid
          friendlyname
          lpid
          lockedstatus
          qatype
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCandidate = /* GraphQL */ `
  mutation UpdateCandidate(
    $input: UpdateCandidateInput!
    $condition: ModelCandidateConditionInput
  ) {
    updateCandidate(input: $input, condition: $condition) {
      id
      interviewqas {
        items {
          id
          candidateid
          friendlyname
          lpid
          lockedstatus
          qatype
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCandidate = /* GraphQL */ `
  mutation DeleteCandidate(
    $input: DeleteCandidateInput!
    $condition: ModelCandidateConditionInput
  ) {
    deleteCandidate(input: $input, condition: $condition) {
      id
      interviewqas {
        items {
          id
          candidateid
          friendlyname
          lpid
          lockedstatus
          qatype
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createInterviewQA = /* GraphQL */ `
  mutation CreateInterviewQA(
    $input: CreateInterviewQAInput!
    $condition: ModelInterviewQAConditionInput
  ) {
    createInterviewQA(input: $input, condition: $condition) {
      id
      candidate {
        id
        interviewqas {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      candidateid
      friendlyname
      interviewqathreads {
        items {
          id
          interviewqaid
          content
          sequence
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      lpid
      lockedstatus
      qatype
      reviewedpreparedanswers {
        items {
          id
          interviewqaid
          targetroleid
          personaid
          targetlpid
          gptmodel
          gptlpadherence
          gptansweranalysis
          gptrewrite
          gptsuggestedquestions
          gptcritique
          completion_tokens
          prompt_tokens
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateInterviewQA = /* GraphQL */ `
  mutation UpdateInterviewQA(
    $input: UpdateInterviewQAInput!
    $condition: ModelInterviewQAConditionInput
  ) {
    updateInterviewQA(input: $input, condition: $condition) {
      id
      candidate {
        id
        interviewqas {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      candidateid
      friendlyname
      interviewqathreads {
        items {
          id
          interviewqaid
          content
          sequence
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      lpid
      lockedstatus
      qatype
      reviewedpreparedanswers {
        items {
          id
          interviewqaid
          targetroleid
          personaid
          targetlpid
          gptmodel
          gptlpadherence
          gptansweranalysis
          gptrewrite
          gptsuggestedquestions
          gptcritique
          completion_tokens
          prompt_tokens
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteInterviewQA = /* GraphQL */ `
  mutation DeleteInterviewQA(
    $input: DeleteInterviewQAInput!
    $condition: ModelInterviewQAConditionInput
  ) {
    deleteInterviewQA(input: $input, condition: $condition) {
      id
      candidate {
        id
        interviewqas {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      candidateid
      friendlyname
      interviewqathreads {
        items {
          id
          interviewqaid
          content
          sequence
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      lpid
      lockedstatus
      qatype
      reviewedpreparedanswers {
        items {
          id
          interviewqaid
          targetroleid
          personaid
          targetlpid
          gptmodel
          gptlpadherence
          gptansweranalysis
          gptrewrite
          gptsuggestedquestions
          gptcritique
          completion_tokens
          prompt_tokens
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createInterviewQAThread = /* GraphQL */ `
  mutation CreateInterviewQAThread(
    $input: CreateInterviewQAThreadInput!
    $condition: ModelInterviewQAThreadConditionInput
  ) {
    createInterviewQAThread(input: $input, condition: $condition) {
      id
      interviewqa {
        id
        candidate {
          id
          createdAt
          updatedAt
          owner
        }
        candidateid
        friendlyname
        interviewqathreads {
          nextToken
        }
        lpid
        lockedstatus
        qatype
        reviewedpreparedanswers {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      interviewqaid
      content
      sequence
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateInterviewQAThread = /* GraphQL */ `
  mutation UpdateInterviewQAThread(
    $input: UpdateInterviewQAThreadInput!
    $condition: ModelInterviewQAThreadConditionInput
  ) {
    updateInterviewQAThread(input: $input, condition: $condition) {
      id
      interviewqa {
        id
        candidate {
          id
          createdAt
          updatedAt
          owner
        }
        candidateid
        friendlyname
        interviewqathreads {
          nextToken
        }
        lpid
        lockedstatus
        qatype
        reviewedpreparedanswers {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      interviewqaid
      content
      sequence
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteInterviewQAThread = /* GraphQL */ `
  mutation DeleteInterviewQAThread(
    $input: DeleteInterviewQAThreadInput!
    $condition: ModelInterviewQAThreadConditionInput
  ) {
    deleteInterviewQAThread(input: $input, condition: $condition) {
      id
      interviewqa {
        id
        candidate {
          id
          createdAt
          updatedAt
          owner
        }
        candidateid
        friendlyname
        interviewqathreads {
          nextToken
        }
        lpid
        lockedstatus
        qatype
        reviewedpreparedanswers {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      interviewqaid
      content
      sequence
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createPersona = /* GraphQL */ `
  mutation CreatePersona(
    $input: CreatePersonaInput!
    $condition: ModelPersonaConditionInput
  ) {
    createPersona(input: $input, condition: $condition) {
      id
      role
      persona
      targettraits
      disc
      discprofile
      reviewedpreparedanswers {
        items {
          id
          interviewqaid
          targetroleid
          personaid
          targetlpid
          gptmodel
          gptlpadherence
          gptansweranalysis
          gptrewrite
          gptsuggestedquestions
          gptcritique
          completion_tokens
          prompt_tokens
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePersona = /* GraphQL */ `
  mutation UpdatePersona(
    $input: UpdatePersonaInput!
    $condition: ModelPersonaConditionInput
  ) {
    updatePersona(input: $input, condition: $condition) {
      id
      role
      persona
      targettraits
      disc
      discprofile
      reviewedpreparedanswers {
        items {
          id
          interviewqaid
          targetroleid
          personaid
          targetlpid
          gptmodel
          gptlpadherence
          gptansweranalysis
          gptrewrite
          gptsuggestedquestions
          gptcritique
          completion_tokens
          prompt_tokens
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePersona = /* GraphQL */ `
  mutation DeletePersona(
    $input: DeletePersonaInput!
    $condition: ModelPersonaConditionInput
  ) {
    deletePersona(input: $input, condition: $condition) {
      id
      role
      persona
      targettraits
      disc
      discprofile
      reviewedpreparedanswers {
        items {
          id
          interviewqaid
          targetroleid
          personaid
          targetlpid
          gptmodel
          gptlpadherence
          gptansweranalysis
          gptrewrite
          gptsuggestedquestions
          gptcritique
          completion_tokens
          prompt_tokens
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createTargetRole = /* GraphQL */ `
  mutation CreateTargetRole(
    $input: CreateTargetRoleInput!
    $condition: ModelTargetRoleConditionInput
  ) {
    createTargetRole(input: $input, condition: $condition) {
      id
      role
      level
      rootjob
      targetlps
      roledescription
      reviewedpreparedanswers {
        items {
          id
          interviewqaid
          targetroleid
          personaid
          targetlpid
          gptmodel
          gptlpadherence
          gptansweranalysis
          gptrewrite
          gptsuggestedquestions
          gptcritique
          completion_tokens
          prompt_tokens
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTargetRole = /* GraphQL */ `
  mutation UpdateTargetRole(
    $input: UpdateTargetRoleInput!
    $condition: ModelTargetRoleConditionInput
  ) {
    updateTargetRole(input: $input, condition: $condition) {
      id
      role
      level
      rootjob
      targetlps
      roledescription
      reviewedpreparedanswers {
        items {
          id
          interviewqaid
          targetroleid
          personaid
          targetlpid
          gptmodel
          gptlpadherence
          gptansweranalysis
          gptrewrite
          gptsuggestedquestions
          gptcritique
          completion_tokens
          prompt_tokens
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTargetRole = /* GraphQL */ `
  mutation DeleteTargetRole(
    $input: DeleteTargetRoleInput!
    $condition: ModelTargetRoleConditionInput
  ) {
    deleteTargetRole(input: $input, condition: $condition) {
      id
      role
      level
      rootjob
      targetlps
      roledescription
      reviewedpreparedanswers {
        items {
          id
          interviewqaid
          targetroleid
          personaid
          targetlpid
          gptmodel
          gptlpadherence
          gptansweranalysis
          gptrewrite
          gptsuggestedquestions
          gptcritique
          completion_tokens
          prompt_tokens
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createLeadershipPrinciple = /* GraphQL */ `
  mutation CreateLeadershipPrinciple(
    $input: CreateLeadershipPrincipleInput!
    $condition: ModelLeadershipPrincipleConditionInput
  ) {
    createLeadershipPrinciple(input: $input, condition: $condition) {
      id
      lp
      lpdescription
      questions {
        items {
          id
          questiontext
          lpid
          createdAt
          updatedAt
        }
        nextToken
      }
      reviewedpreparedanswers {
        items {
          id
          interviewqaid
          targetroleid
          personaid
          targetlpid
          gptmodel
          gptlpadherence
          gptansweranalysis
          gptrewrite
          gptsuggestedquestions
          gptcritique
          completion_tokens
          prompt_tokens
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateLeadershipPrinciple = /* GraphQL */ `
  mutation UpdateLeadershipPrinciple(
    $input: UpdateLeadershipPrincipleInput!
    $condition: ModelLeadershipPrincipleConditionInput
  ) {
    updateLeadershipPrinciple(input: $input, condition: $condition) {
      id
      lp
      lpdescription
      questions {
        items {
          id
          questiontext
          lpid
          createdAt
          updatedAt
        }
        nextToken
      }
      reviewedpreparedanswers {
        items {
          id
          interviewqaid
          targetroleid
          personaid
          targetlpid
          gptmodel
          gptlpadherence
          gptansweranalysis
          gptrewrite
          gptsuggestedquestions
          gptcritique
          completion_tokens
          prompt_tokens
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteLeadershipPrinciple = /* GraphQL */ `
  mutation DeleteLeadershipPrinciple(
    $input: DeleteLeadershipPrincipleInput!
    $condition: ModelLeadershipPrincipleConditionInput
  ) {
    deleteLeadershipPrinciple(input: $input, condition: $condition) {
      id
      lp
      lpdescription
      questions {
        items {
          id
          questiontext
          lpid
          createdAt
          updatedAt
        }
        nextToken
      }
      reviewedpreparedanswers {
        items {
          id
          interviewqaid
          targetroleid
          personaid
          targetlpid
          gptmodel
          gptlpadherence
          gptansweranalysis
          gptrewrite
          gptsuggestedquestions
          gptcritique
          completion_tokens
          prompt_tokens
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion(
    $input: CreateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    createQuestion(input: $input, condition: $condition) {
      id
      questiontext
      leadershipprinciple {
        id
        lp
        lpdescription
        questions {
          nextToken
        }
        reviewedpreparedanswers {
          nextToken
        }
        createdAt
        updatedAt
      }
      lpid
      createdAt
      updatedAt
    }
  }
`;
export const updateQuestion = /* GraphQL */ `
  mutation UpdateQuestion(
    $input: UpdateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    updateQuestion(input: $input, condition: $condition) {
      id
      questiontext
      leadershipprinciple {
        id
        lp
        lpdescription
        questions {
          nextToken
        }
        reviewedpreparedanswers {
          nextToken
        }
        createdAt
        updatedAt
      }
      lpid
      createdAt
      updatedAt
    }
  }
`;
export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion(
    $input: DeleteQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    deleteQuestion(input: $input, condition: $condition) {
      id
      questiontext
      leadershipprinciple {
        id
        lp
        lpdescription
        questions {
          nextToken
        }
        reviewedpreparedanswers {
          nextToken
        }
        createdAt
        updatedAt
      }
      lpid
      createdAt
      updatedAt
    }
  }
`;
export const createReviewedPreparedAnswer = /* GraphQL */ `
  mutation CreateReviewedPreparedAnswer(
    $input: CreateReviewedPreparedAnswerInput!
    $condition: ModelReviewedPreparedAnswerConditionInput
  ) {
    createReviewedPreparedAnswer(input: $input, condition: $condition) {
      id
      interviewqa {
        id
        candidate {
          id
          createdAt
          updatedAt
          owner
        }
        candidateid
        friendlyname
        interviewqathreads {
          nextToken
        }
        lpid
        lockedstatus
        qatype
        reviewedpreparedanswers {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      interviewqaid
      targetrole {
        id
        role
        level
        rootjob
        targetlps
        roledescription
        reviewedpreparedanswers {
          nextToken
        }
        createdAt
        updatedAt
      }
      targetroleid
      persona {
        id
        role
        persona
        targettraits
        disc
        discprofile
        reviewedpreparedanswers {
          nextToken
        }
        createdAt
        updatedAt
      }
      personaid
      targetlp {
        id
        lp
        lpdescription
        questions {
          nextToken
        }
        reviewedpreparedanswers {
          nextToken
        }
        createdAt
        updatedAt
      }
      targetlpid
      gptmodel
      gptlpadherence
      gptansweranalysis
      gptrewrite
      gptsuggestedquestions
      gptcritique
      completion_tokens
      prompt_tokens
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateReviewedPreparedAnswer = /* GraphQL */ `
  mutation UpdateReviewedPreparedAnswer(
    $input: UpdateReviewedPreparedAnswerInput!
    $condition: ModelReviewedPreparedAnswerConditionInput
  ) {
    updateReviewedPreparedAnswer(input: $input, condition: $condition) {
      id
      interviewqa {
        id
        candidate {
          id
          createdAt
          updatedAt
          owner
        }
        candidateid
        friendlyname
        interviewqathreads {
          nextToken
        }
        lpid
        lockedstatus
        qatype
        reviewedpreparedanswers {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      interviewqaid
      targetrole {
        id
        role
        level
        rootjob
        targetlps
        roledescription
        reviewedpreparedanswers {
          nextToken
        }
        createdAt
        updatedAt
      }
      targetroleid
      persona {
        id
        role
        persona
        targettraits
        disc
        discprofile
        reviewedpreparedanswers {
          nextToken
        }
        createdAt
        updatedAt
      }
      personaid
      targetlp {
        id
        lp
        lpdescription
        questions {
          nextToken
        }
        reviewedpreparedanswers {
          nextToken
        }
        createdAt
        updatedAt
      }
      targetlpid
      gptmodel
      gptlpadherence
      gptansweranalysis
      gptrewrite
      gptsuggestedquestions
      gptcritique
      completion_tokens
      prompt_tokens
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteReviewedPreparedAnswer = /* GraphQL */ `
  mutation DeleteReviewedPreparedAnswer(
    $input: DeleteReviewedPreparedAnswerInput!
    $condition: ModelReviewedPreparedAnswerConditionInput
  ) {
    deleteReviewedPreparedAnswer(input: $input, condition: $condition) {
      id
      interviewqa {
        id
        candidate {
          id
          createdAt
          updatedAt
          owner
        }
        candidateid
        friendlyname
        interviewqathreads {
          nextToken
        }
        lpid
        lockedstatus
        qatype
        reviewedpreparedanswers {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      interviewqaid
      targetrole {
        id
        role
        level
        rootjob
        targetlps
        roledescription
        reviewedpreparedanswers {
          nextToken
        }
        createdAt
        updatedAt
      }
      targetroleid
      persona {
        id
        role
        persona
        targettraits
        disc
        discprofile
        reviewedpreparedanswers {
          nextToken
        }
        createdAt
        updatedAt
      }
      personaid
      targetlp {
        id
        lp
        lpdescription
        questions {
          nextToken
        }
        reviewedpreparedanswers {
          nextToken
        }
        createdAt
        updatedAt
      }
      targetlpid
      gptmodel
      gptlpadherence
      gptansweranalysis
      gptrewrite
      gptsuggestedquestions
      gptcritique
      completion_tokens
      prompt_tokens
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createCustomerResumeProfile = /* GraphQL */ `
  mutation CreateCustomerResumeProfile(
    $input: CreateCustomerResumeProfileInput!
    $condition: ModelCustomerResumeProfileConditionInput
  ) {
    createCustomerResumeProfile(input: $input, condition: $condition) {
      pk
      sk
      s3filename
      isactive
      isresume
      processedbygpt
      extracttext
      completion_tokens
      prompt_tokens
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCustomerResumeProfile = /* GraphQL */ `
  mutation UpdateCustomerResumeProfile(
    $input: UpdateCustomerResumeProfileInput!
    $condition: ModelCustomerResumeProfileConditionInput
  ) {
    updateCustomerResumeProfile(input: $input, condition: $condition) {
      pk
      sk
      s3filename
      isactive
      isresume
      processedbygpt
      extracttext
      completion_tokens
      prompt_tokens
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCustomerResumeProfile = /* GraphQL */ `
  mutation DeleteCustomerResumeProfile(
    $input: DeleteCustomerResumeProfileInput!
    $condition: ModelCustomerResumeProfileConditionInput
  ) {
    deleteCustomerResumeProfile(input: $input, condition: $condition) {
      pk
      sk
      s3filename
      isactive
      isresume
      processedbygpt
      extracttext
      completion_tokens
      prompt_tokens
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createOnboardResumeRequest = /* GraphQL */ `
  mutation CreateOnboardResumeRequest(
    $input: CreateOnboardResumeRequestInput!
    $condition: ModelOnboardResumeRequestConditionInput
  ) {
    createOnboardResumeRequest(input: $input, condition: $condition) {
      pk
      sk
      sk_resume
      interviewqaid
      gptoutline
      completion_tokens
      prompt_tokens
      isprocessed
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateOnboardResumeRequest = /* GraphQL */ `
  mutation UpdateOnboardResumeRequest(
    $input: UpdateOnboardResumeRequestInput!
    $condition: ModelOnboardResumeRequestConditionInput
  ) {
    updateOnboardResumeRequest(input: $input, condition: $condition) {
      pk
      sk
      sk_resume
      interviewqaid
      gptoutline
      completion_tokens
      prompt_tokens
      isprocessed
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteOnboardResumeRequest = /* GraphQL */ `
  mutation DeleteOnboardResumeRequest(
    $input: DeleteOnboardResumeRequestInput!
    $condition: ModelOnboardResumeRequestConditionInput
  ) {
    deleteOnboardResumeRequest(input: $input, condition: $condition) {
      pk
      sk
      sk_resume
      interviewqaid
      gptoutline
      completion_tokens
      prompt_tokens
      isprocessed
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createCustomerSubscription = /* GraphQL */ `
  mutation CreateCustomerSubscription(
    $input: CreateCustomerSubscriptionInput!
    $condition: ModelCustomerSubscriptionConditionInput
  ) {
    createCustomerSubscription(input: $input, condition: $condition) {
      id
      stripe_id
      subscription_level
      subscription_interval
      subscription_interval_count
      subscription_id
      plan_id
      plan_active
      start_date
      status
      next_billing_date
      last_webhook_received
      last_webhook_event
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomerSubscription = /* GraphQL */ `
  mutation UpdateCustomerSubscription(
    $input: UpdateCustomerSubscriptionInput!
    $condition: ModelCustomerSubscriptionConditionInput
  ) {
    updateCustomerSubscription(input: $input, condition: $condition) {
      id
      stripe_id
      subscription_level
      subscription_interval
      subscription_interval_count
      subscription_id
      plan_id
      plan_active
      start_date
      status
      next_billing_date
      last_webhook_received
      last_webhook_event
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomerSubscription = /* GraphQL */ `
  mutation DeleteCustomerSubscription(
    $input: DeleteCustomerSubscriptionInput!
    $condition: ModelCustomerSubscriptionConditionInput
  ) {
    deleteCustomerSubscription(input: $input, condition: $condition) {
      id
      stripe_id
      subscription_level
      subscription_interval
      subscription_interval_count
      subscription_id
      plan_id
      plan_active
      start_date
      status
      next_billing_date
      last_webhook_received
      last_webhook_event
      createdAt
      updatedAt
    }
  }
`;
