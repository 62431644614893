import React from 'react'
import { ListItem, ListItemText, IconButton, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'

import CustomToolTip from './CustomToolTip'

function ResumeItem({ resume, onDelete, onMakeActive }) {
    const formatDate = (dateString) => {
        const date = new Date(dateString)
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
    }

    const bgColor = resume.isresume ? 'white' : 'mistyrose'
    const activeBgColor = resume.isactive ? 'lightgreen' : bgColor
    const makeActiveOutline = !resume.isactive && resume.isresume && '1px solid lightgreen'

    const tooltipFontSize='1.1em' //I really should move this out to a config file and import it for use

    const conditiontext1 = "You cannot mark a resume active until it has been processed by the system. The background of the row will be green once it has been processed. Please be patient."
    const conditiontext2 = "The file you uploaded was evaluated as not a resume. Please delete this one and upload a different file."
    const conditiontext3 = "Make this the Active resume? Making a resume as Active will cause it to be used for the basis of the analysis whenever you click the 'Evaluate My Answer' button."
    const conditiontext4 = "This is your active resume."
    
    return (
        <ListItem style={{ backgroundColor: activeBgColor, border: makeActiveOutline }}>
            <Typography variant="subtitle1" sx={{width : "30%", paddingRight:"4px"}}>{resume.isactive ? "This is your active resume" :
                    (resume.isresume ? "This resume is available to be made active" : "This resume is not yet processed.") }</Typography>
            <ListItemText primary={"Filename: " + resume.s3filename} secondary={"Date Uploaded: " + formatDate(resume.sk)} />
            <IconButton edge="end" aria-label="delete" onClick={() => onDelete(resume)}>
                <DeleteIcon />
            </IconButton>
            <CustomToolTip
                // title={resume.isresume && !resume.isactive ? "Make this the Active resume? Making a resume as Active will cause it to be used for the basis of the analysis whenever you click the 'Evaluate My Answer' button." : "You cannot mark a resume active until it has been processed by the system. The background of the row will be green once it has been processed. Please be patient."}
                title={!resume.processedbygpt ? conditiontext1 : (!resume.isresume ? conditiontext2 :  (!resume.isactive ? conditiontext3 : conditiontext4))}
                tooltipFontSize={tooltipFontSize}
                >
                <span>
                <IconButton edge="end" aria-label="make active" onClick={onMakeActive} disabled={!resume.isresume}>
                    {resume.isactive ? <CheckCircleIcon color="primary" /> : <RadioButtonUncheckedIcon />}
                </IconButton></span>
            </CustomToolTip>
        </ListItem>
    )
}

export default ResumeItem