import React, { useState } from "react"
import {  useNavigate } from "react-router-dom"

import { AppBar, Toolbar, IconButton, Typography, MenuItem, Menu } from "@mui/material"
import { Menu as MenuIcon } from "@mui/icons-material"
import styled from "@emotion/styled"

import { useAuthenticator } from '@aws-amplify/ui-react'

const StyledAppBar = styled(AppBar)`
    position: fixed;
    background-color: #af3737;
    width: 100%;
    top: 0;
    z-index: 999;
`

function Navbar() {
    const { route, signOut } = useAuthenticator((context) => [context.route, context.signOut])
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    const navigate = useNavigate()

    function handleMenu (event) {
        setAnchorEl(event.currentTarget)
    }

    function handleClose (route) {
        setAnchorEl(null)
        if (route) {
            navigate(route)
        }
    }

    function handleSignout () {
        setAnchorEl(null)
        signOut()
        navigate("/")
    }   

    return (
        <StyledAppBar position="static">
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={handleMenu}
                >
                    <MenuIcon />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => handleClose()}
                >
                    <MenuItem onClick={() => handleClose("/")}>
                        Home
                    </MenuItem>
                    { route === 'authenticated' ? [
                        <MenuItem key="interviewqa" onClick={() => handleClose("/interviewqa")}>
                            Story Editor
                        </MenuItem>,
                        <MenuItem key="reports" onClick={() => handleClose("/reports")}>
                            Story Evaluations
                        </MenuItem>,
                        <MenuItem key="profile" onClick={() => handleClose("/profile")}>
                            Profile
                        </MenuItem>,
                        <MenuItem key="signout" onClick={() => handleSignout()}>
                            Sign Out
                        </MenuItem>
                    ] : [
                        // <MenuItem key="faq" onClick={() => handleClose("/faq")}>
                        //     FAQ
                        // </MenuItem>,
                        // <MenuItem key="reports" onClick={() => handleClose("/reports")}>
                        //     Evaluated Reports
                        // </MenuItem>,
                        <MenuItem key="demo" onClick={() => handleClose("/demo")}>
                            DEMO - Show me an Evaluation
                        </MenuItem>,
                        <MenuItem key="userauth" onClick={() => handleClose("/userauth")}>
                            Log In / Sign Up
                        </MenuItem>
                    ]}
                </Menu>
                <Typography variant="h6">
                    Loopalla
                </Typography>
            </Toolbar>
        </StyledAppBar>
    )
}

export default Navbar