import React, { useState, useEffect } from 'react'

import { listCustomerResumeProfiles } from '../graphql/queries'

import UserProfileInfo from '../components/UserProfileInfo'
import CustomerResumeDrop from '../components/CustomerResumeDrop'
import CustomerResumePicker from '../components/CustomerResumePicker'
import CustomerSubscription from '../components/CustomerSubscription'

import { useAuthenticator } from '@aws-amplify/ui-react'
import { API, graphqlOperation } from 'aws-amplify'

//IMPORTANT REMOVED USER PROP
function UserResume () {
    const { user } = useAuthenticator((context) => [context.user])
    const { route } = useAuthenticator((context) => [context.route])
    
    // GraphQL query to get resumes for the user
    const [resumes, setResumes] = useState([])

    useEffect(() => {
        async function fetchResumes() {
            try {
                const result = await API.graphql(
                    graphqlOperation(listCustomerResumeProfiles, {
                        pk: user.username,
                    })
                )
                setResumes(result.data.listCustomerResumeProfiles.items)
            } catch (error) {
                console.error('Error fetching resumes:', error)
            }
        }

        fetchResumes()
    }, [user])

    return (
        <>
            { route === "authenticated" ? 
                (
                    <div>
                        <UserProfileInfo user={user} />
                        <CustomerSubscription user={user} />
                        <CustomerResumeDrop user={user} resumes={resumes} setResumes={setResumes} />
                        <CustomerResumePicker user={user} resumes={resumes} setResumes={setResumes} />
                    </div>
                ): ("loading user profile...")
            }
        </>
    )
}

export default UserResume