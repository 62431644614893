import React, { useEffect } from "react"
import { useNavigate, useLocation } from "react-router-dom"

import {
    Authenticator,
    useAuthenticator,
    View,
    ThemeProvider,
    useTheme,
    Button,
} from "@aws-amplify/ui-react"

import '../css/customauthenticator.css'

function UserAuth() {
    const { route } = useAuthenticator((context) => [context.route])
    const location = useLocation()
    const navigate = useNavigate()

    let from = location.state?.from?.pathname || '/'

    useEffect(() => {
        if (route === 'authenticated') {
            navigate(from, { replace: true });
        }
    }, [route, navigate, from])

    const currentTheme = useTheme()
    const theme = {
        ...currentTheme,
        tokens: {
            ...currentTheme.tokens,
            colors: {
                ...currentTheme.tokens.colors,
                primary: "#ae3737",
                gray: "gray",
                darkGray: "darkgray",
            },
            components: {
                ...currentTheme.tokens.components,
                button: {
                    primary: {
                        backgroundColor: "#ae3737",
                        color: "#ffffff",
                        _hover: {
                            backgroundColor: "#8d2c2c",
                        },
                    },
                },
                textField: {
                    label: {
                        color: currentTheme.tokens.colors.gray,
                        fontWeight: "bold",
                    },
                    _focus: {
                        label: {
                            color:"#ae3737",
                        },
                    },
                },
                tabs: {
                    line: {
                        borderColor: "#ae3737",
                    },
                    item: {
                        borderColor: "transparent",
                        _focus: {
                            backgroundColor: "white",
                            color: "#ae3737",
                            borderColor: "transparent",
                        },
                        _hover: {
                            backgroundColor: "darkgray",
                        },
                        _active: {
                            borderColor: {
                                // horizontal: "transparent",
                                bottom: "yellow",
                            },
                            color: "#ae3737",
                            backgroundColor: "white",
                        },
                    },
                    header: {
                        borderColor: {
                            bottom: "#ae3737",
                        },
                    },
                },
                container: {
                    borderColor: "black",
                    borderWidth: "2px",
                },
            },
        },
    }

    const components = {
        SignIn: {
            Footer() {
                const { toResetPassword } = useAuthenticator()

                return (
                    <View textAlign="center">
                        <Button
                            fontWeight="normal"
                            onClick={toResetPassword}
                            size="small"
                            variation="link"
                            style={{
                                color: theme.tokens.colors.primary,
                                textDecoration: "none",
                            }}
                            onMouseOver={(event) => { event.target.style.color = "#bf6363"; }}
                            onMouseOut={(event) => { event.target.style.color = theme.tokens.colors.primary; }}
                        >
                            Reset Password
                        </Button>
                    </View>
                )
            },
        },
    }

    return (
        <ThemeProvider theme={theme}>
            <Authenticator
                initialState="signIn"
                components={components}
            />
        </ThemeProvider>
    )
}

export default UserAuth