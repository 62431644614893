import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Container, Typography, Button, Box } from '@mui/material'

function HeroTopLandingPage() {
    function handleCTAClick() {
        navigate("/userauth")
    }
    const navigate = useNavigate()

    return (
        <>
            <Box sx={{ bgcolor: 'background.paper', marginBottom: "40px" }}>
                <Container maxWidth="lg">
                    {/* Component 1 */}
                    <Box sx={{ marginTop: 4 }}>
                        <Typography
                            variant="h4"
                            component="h1"
                            sx={{paddingRight: "20px", paddingLeft: "20px", marginBottom: "20px"}}
                        >
                            Land Your Dream Job
                        </Typography>
                        <Typography
                            variant="h6"
                            component="h6"
                            sx={{paddingBottom: '10px'}}
                        >
                            Loopalla uses AI + Experience to help you prepare your interview stories so that you stand out in your interviews.
                        </Typography>
                        {/* <Button
                            variant="contained"
                            sx={{backgroundColor: "#ae3737"}}
                            onClick={handleCTAClick}
                        >
                            Get Started Now!
                        </Button> */}
                    </Box>
                    {/* Component 2 */}
                </Container>
            </Box>
        </>
    )
}

export default HeroTopLandingPage