import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Grid, Typography, Box, Button } from '@mui/material'

const illustrationNameBegin = "../images/illustrations/"

const data = [
    {
        title: 'Old Habits Can Hold You Back',
        imageUrl: illustrationNameBegin + 'process_reinvention.svg',
        description: 'It\'s easy to get caught up in preparing for interviews, only to overlook the quality of your stories. By focusing on crafting compelling, well-structured narratives, you\'ll captivate your interviewer and showcase your true potential.',
    },
    {
        title: 'Reduce Interview Anixety',
        imageUrl: illustrationNameBegin + 'artificial_intelligence.svg',
        description: 'Imagine leveraging the expertise of top companies to elevate your interview stories. With Loopalla, you\'ll benefit from AI-driven feedback in mere minutes, eliminating the need for time-consuming coaching sessions and ensuring your stories truly shine.',
    },
    {
        title: 'Stand Out & Secure Your Dream Job',
        imageUrl: illustrationNameBegin + 'new_job.svg',
        description: 'Our platform provides the tools and guidance you need to effectively structure your stories and impress even the most discerning employers. Loopalla empowers you to focus on demonstrating your strengths and skills, transforming your interview performance and helping you land your dream job. Ready to get started?',
    },
]

function FeaturesCarousel () {
    function handleCTAClick() {
        navigate("/userauth")
    }

    function handleDemoClick() {
        navigate("/demo")
    }

    const navigate = useNavigate()

    return (
        <>
            <Typography
                variant="h2"
                sx={{marginTop: '20px', marginBottom: '20px', fontWeight: 'bold'}}
            >
                Transform Your Job Interview Preparation
            </Typography>
            <Grid
                container justifyContent="space-around"
            >
                {data.map(({ title, imageUrl, description }, index) => (
                    <Grid item xs={12} sm={4} key={index} style={{ marginTop: '15px', px: '20px' }}>
                        <Box style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography
                                variant="h6"
                                component="h3"
                                sx={{marginBottom: '10px'}}
                            >
                                {title}
                            </Typography>
                            <Box style={{ height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <img src={imageUrl} alt={title} style={{ maxWidth: 300 }} />
                            </Box>
                            <Typography
                                sx={{py: '10px', px: '30px', textAlign: 'justify'}}
                            >{description}</Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
            <Button
                variant="contained"
                sx={{
                    marginTop: '20px',
                    mx: "20px",
                    width: '230px',
                    backgroundColor: "#ae3737",
                    '&:hover': {
                        backgroundColor: "#8d2c2c"}
                ,}}
                onClick={handleDemoClick}
            >
                Show Me a Working Demo
            </Button>
            <Button
                variant="contained"
                sx={{
                    marginTop: '20px',
                    mx: "20px",
                    width: '230px',
                    backgroundColor: "#ae3737",
                    '&:hover': {
                        backgroundColor: "#8d2c2c"}
                }}
                onClick={handleCTAClick}
            >
                Start Using Loopalla
            </Button>

        </>
    )
}

export default FeaturesCarousel