import React, { useState } from 'react'

import { API, graphqlOperation, Storage } from 'aws-amplify'
import { updateCustomerResumeProfile, deleteCustomerResumeProfile } from '../graphql/mutations'

import { Button, List, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material'

import ResumeItem from './ResumeItem'
//import CustomToolTip from './CustomToolTip'

function CustomerResumePicker ({ user, resumes, setResumes }) {
    const [selectedresume, setSelectedResume] = useState(null)
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

    //const tooltipFontSize='1.1em' //I really should move this out to a config file and import it for use

    async function handleMakeActive(resume) {
        console.log("handleMakeActive was called")
        try {
            const previousActive = resumes.find(resume => resume.isactive)
            
            if (previousActive) {
                await API.graphql(graphqlOperation(updateCustomerResumeProfile, {
                    input: { pk: user.username, sk: previousActive.sk, isactive: false }
                }))
            }
            

            await API.graphql(graphqlOperation(updateCustomerResumeProfile, {
                input: { pk: user.username, sk: resume.sk, isactive: true }
            }))

            // Create a new updatedResumes array with the updated active states
            const updatedResumes = resumes.map((item) => {
                if (item === previousActive) return { ...item, isactive: false }
                if (item === resume) return { ...item, isactive: true }
                return item
            })

            setResumes(updatedResumes)
        } catch (error) {
            console.error('Error making resume active:', error)
        }
    }

    async function handleDelete () {
        try {
            await Storage.remove(selectedresume.s3filename)
    
            await API.graphql(graphqlOperation(deleteCustomerResumeProfile, {
                input: {
                    pk: user.username,
                    sk: selectedresume.sk
                }
            }))
            
            const updatedResumes = resumes.filter(resume => resume !== selectedresume);
            setResumes(updatedResumes)
        } catch (error) {
            console.error('Error deleting resume:', error)
        }
    }

    function handleDeleteDialogClose(confirmed) {
        setDeleteDialogOpen(false)
        if (confirmed) handleDelete()
    }

    const hasActiveResume = resumes.some(resume => resume.isactive)

    return (
        <div>
            <br/>
            <div>
                <Typography variant="h6">Manage Your Resumes and Mark One Active</Typography>
                <Typography variant="body1" sx={{padding:"0px 100px", textAlign:"left"}}>With an active resume, the system can generate starter stories for you which includes a customized question targeting a specific experience on your resume, and also create an robust ouline of a STAR format answer to get you started.</Typography>
                <Typography variant="subtitle2" sx={{padding:"0px 100px", textAlign:"left"}}>NOTE: The quality of what we can generate depends on the quality and detail of your resume.</Typography>
                <List>
                    {resumes.map(resume => (
                        <ResumeItem
                            key={resume.sk}
                            resume={resume}
                            //selected={selectedresume === resume}
                            onDelete={(resumetodelete) => {setSelectedResume(resumetodelete)
                                                            setDeleteDialogOpen(true)}}
                            onMakeActive={() => { handleMakeActive(resume) }} />
                    ))}
                </List>
            <Dialog open={deleteDialogOpen} onClose={() => handleDeleteDialogClose(false)}>
                <DialogTitle>Delete Resume</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to delete {selectedresume?.s3filename}? This cannot be undone.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDeleteDialogClose(true)} autoFocus>Yes</Button>
                    <Button onClick={() => handleDeleteDialogClose(false)}>No</Button>
                </DialogActions>
            </Dialog>
            </div>
            {!hasActiveResume && <Typography variant="subtitle2">You do not have any resume presently marked as Active.</Typography>}
            <Typography variant="subtitle1">You have {resumes.length} resumes uploaded.</Typography>
        </div>
    )
}

export default CustomerResumePicker