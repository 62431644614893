import React, { useState, useEffect } from 'react'
import { TextField } from '@mui/material'

function InterviewQAThreadContent({ thread, onChange, onBlur, lockedstatus }) {
    const [content, setContent] = useState(thread.content)

    useEffect(() => {
        setContent(thread.content)
    }, [thread])

    function gettitle(sequence) {
        if (sequence === 1) return `${sequence} - Interview Question`
        if (sequence === 2) return `${sequence} - Your Answer`

        if (sequence % 2 !== 0) {
            return `${sequence} - Follow Up Question`
        }
        return `${sequence} - Follow Up Answer`
    }

    const isEditable = !lockedstatus || (thread.sequence !== 1)

    function handleContentChange(e) {
        const newContent = e.target.value
        setContent(newContent)
        if (onChange && isEditable) {
            onChange(newContent)
        }
    }

    function handleContentBlur(e) {
        onBlur && onBlur();
    }

    return (
        <div>
            <br />
            <TextField
                label={gettitle(thread.sequence)}
                InputLabelProps={{
                    style: {
                    fontSize: thread.content ? '1.2rem' : '1rem',
                    },
                }}
                fullWidth
                multiline
                minRows={1}
                value={content}
                onChange={handleContentChange}
                onBlur={handleContentBlur}
                readOnly={!isEditable}
            />
            <br />
        </div>
    )
}

export default InterviewQAThreadContent