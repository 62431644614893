import React from 'react'

import InterviewQAReport from './InterviewQAReport'

function ReportDemo() {
    return (
        <InterviewQAReport demo={true} />
    )
}

export default ReportDemo