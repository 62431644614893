import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { Grid, Typography, Button, ButtonGroup, Box } from '@mui/material'

import { API, graphqlOperation } from 'aws-amplify'

import { getCandidate, getReviewedPreparedAnswer } from '../graphql/queries'
import { interviewqaWithThreadAndReviewed } from '../graphql/customqueries'

import ReportCard from '../components/ReportCard'

import { useAuthenticator } from '@aws-amplify/ui-react'

import demoData from '../data/DemoData.js'

const evals = {
    'gptlpadherence': {
        'heading': "How well did you address the high order topic of the question?",
        'button': "Quick Analysis"
    },
    'gptansweranalysis': {
        'heading': "How good was your answer overall?",
        'button': "Overall Quality",
    },
    'gptsuggestedquestions': {
        'heading': "What are some clear follow-on questions you left unanswered?",
        'button': "Follow Up Qs",
    },
    'gptrewrite': {
        'heading': "What would a re-write look like addressing these issues?",
        'button': "Rewritten Answer"
    },
    'gptcritique': {
        'heading': "In what ways was this rewrite better?",
        'button': "Answer Comparisons"
    }
}

const stillworking = [
    "The hamsters are still running on the wheel - please be patient.",
    "If Rome wasn't built in a day, it's reasonable to ask you to hold on for a few minutes.",
    "Time flies - except when you are waiting for something important. :)",
    "Our code ninjas are battling it out - just give them a moment to regroup.",
    "Good things come to those who wait. And you're waiting for greatness!",
    "Just like a fine wine, great code takes time to age to perfection.",
    "Our tech wizards are casting a spell – hold tight as the magic happens!",
    "We're brewing up an app-flavored coffee - patience is the secret ingredient.",
    "Think of this wait as a short intermission – go grab some popcorn and we'll be ready soon.",
    "We're taming rambunctious electrons– stay tuned for their next electrifying performance!",
    "Our application is in the midst of a digital metamorphosis – prepare for the stunning transformation!",
    "Patience is a virtue, and our tech-savvy superheroes are working to unleash a world of opportunities for you!",
    "We're fine-tuning our secret sauce recipe – get ready to be served a marvelous digital feast!",
    "A little wait here means limitless possibilities ahead. Let us unleash your awesome techno-future!",
    "While you wait, picture yourself acing that tech-job interview thanks to our app – it's coming soon!",
    "As our trusty algorithm stitches together your success story, take a moment to savor the suspense.",
    "Our digital craftsmen are adding the final touches – your shot at tech-industry victory is on the horizon!",
    "Your dream tech career awaits, and this brief pause is but a tiny stepping stone to those heights of achievement!"
]

const stillworking_finish = "\nWe are still in early product dev - can you refresh the page to get your updates? :)"

function InterviewQAReport( { demo }) {
    const { user } = useAuthenticator((context) => [context.user])
    const { route } = useAuthenticator((context) => [context.route])
    const [candidate, setCandidate] = useState({})
    const [interviewQAs, setInterviewQAs] = useState([])
    const [selectedCard, setSelectedCard] = useState(null)
    const [selectedReport, setSelectedReport] = useState(null)
    const [selectedFilter, setSelectedFilter] = useState('gptlpadherence')
    const [selectedMuiCardId, setSelectedMuiCardId] = useState(null)
    const [evaluations, setEvaluations] = useState(evals)
    const [missingFields, setMissingFields] = useState([])
    const location = useLocation()
    const [searchParams, setSearchParams] = useState(null)

    useEffect(() => {
        //console.log("Logging: in useEffect, user: ", user)
        //console.log("Logging: in useEffect, route: ", route)
        
        // if(user) {
        //     fetchInterviewQAs()
        // }
        // else if (demo){
            fetchInterviewQAs()
        // }
    }, [user, route, demo])

    useEffect(() => {
        setSearchParams(new URLSearchParams(location.search))
    }, [location.search])

    useEffect(() => {
        if (searchParams) {
            const interviewqaid = searchParams.get('interviewqaid')
            const reviewedAnswerId = searchParams.get('reviewedAnswerId')
    
            if (searchParams && interviewqaid) {
                const selectedQA = interviewQAs.find(qa => qa.id === interviewqaid)
                if (selectedQA) {
                    handleCardClick(selectedQA)
                    if (reviewedAnswerId) {
                        const report = selectedQA.reviewedpreparedanswers.items.find(re => re.id === reviewedAnswerId)
                        if (report) {
                            handleReportClick(report)
                        }
                    }
                }
            }
        }
    }, [interviewQAs, searchParams])

    useEffect(() => {
        if (selectedReport) {
            setSelectedFilter('gptlpadherence')
        }
    }, [selectedReport])

    async function fetchUpdatedReport(id) {
        if (demo) {
            //I need to just load the reviewedPreparedAnswer data from the demoData
            //and since I know there is a limited set, just filter that based on the id
            const updatedReport = demoData.ReviewedPreparedAnswers.filter(report => report.id === id)[0]

            const newMissingFields = Object.keys(evals).filter(key => !updatedReport[key])

            if (newMissingFields.length === 0) {
                setSelectedReport(updatedReport)
            }
            setMissingFields(newMissingFields)
        }
        else {
            if (user) {
                try {
                    const updatedReportData = await API.graphql(
                        graphqlOperation(getReviewedPreparedAnswer, { id: id })
                    )
                    const updatedReport = updatedReportData.data.getReviewedPreparedAnswer

                    const newMissingFields = Object.keys(evals).filter(key => !updatedReport[key])
                    if (newMissingFields.length === 0) {
                        setSelectedReport(updatedReport)
                    }
                    setMissingFields(newMissingFields)
                } catch (error) {
                    console.error(error)
                }
            }
        }
    }

    async function fetchInterviewQAs() {
        const limit = 100
        if (demo) {
            //load the InterviewQA data from the demo data file
            //setCandidate(demoData.candidate)

            const filteredData = demoData.interviewQAs.data.interviewqaByCandidateId.items.filter(
                    item => item.reviewedpreparedanswers.items.length > 0
                )

            setInterviewQAs(filteredData)
        }
        else {
            if (user) {
                try {
                    const candidateData = await API.graphql(
                        graphqlOperation(getCandidate, { id: user.username })
                    )
                    const candidate = candidateData.data.getCandidate

                    setCandidate(candidate)

                    if (candidate) {
                        const interviewQAData = await API.graphql(
                            graphqlOperation(interviewqaWithThreadAndReviewed, {
                                candidateid: candidate.id,
                                limit: limit
                            })
                        )

                        const filteredData = interviewQAData.data.interviewqaByCandidateId.items.filter(
                            item => item.reviewedpreparedanswers.items.length > 0
                        )
                        setInterviewQAs(filteredData)
                    }
                } catch (error) {
                    console.error(error)
                }
            }
        }
    }

    const handleButtonClick = filter => {
        setSelectedFilter(filter)
    }

    function renderThreadContent(sequence) {
        switch (sequence) {
            case 1:
                return <Typography variant="button" display="block" component="div">Question</Typography>
            case 2:
                return <Typography variant="button" display="block" component="div">Your Answer</Typography>
            case sequence% 2 === 1:
                return <Typography variant="button" display="block" component="div">Follow Up Question</Typography>
            case sequence% 2 === 0:
                return <Typography variant="button" display="block" component="div">Follow Up Answer</Typography>
            default:
                return null
        }
    }

    function handleCardClick(card) {
        if (selectedCard && card.id === selectedCard.id) {
            return
        } else {
            setSelectedCard(card)
            setSelectedReport(null)
        }
    }

    function handleReportClick(report) {
        setSelectedReport(selectedReport && report.id === selectedReport.id ? null : report)
        setSelectedMuiCardId(report.id)
        fetchUpdatedReport(report.id)
    }

    function getRandomStillWorkingMessage() {   
        const randomIndex = Math.floor(Math.random() * stillworking.length)
        return stillworking[randomIndex]
    }

    const threadLoop = selectedReport && selectedCard["interviewqathreads"]["items"]
        .sort((a, b) => a.sequence - b.sequence) // Add sorting logic here
        .map(thread => (
            <React.Fragment key={thread.id}>
                {renderThreadContent(thread.sequence)}
                <Typography
                    variant="body1"
                    component="div"
                    align="left"
                    display="block"
                    sx={{mb:"10px", mt: "10px"}}
                >
                    <div style={{ whiteSpace: 'pre-wrap' }}>
                        {thread.content}
                    </div>
                </Typography>
            </React.Fragment>
        ))

    function renderCustomer(){
        return(
                <Grid container spacing={2} >
                    <Grid item xs={3} style={{ position: "sticky", top: "64px" }}>
                    <div style={{
                        maxHeight: 'calc(100vh - 64px)',
                        overflowY: 'auto',
                        paddingRight: '16px',
                    }}>
                        {interviewQAs.map(qa => (
                            <ReportCard
                                key={qa.id}
                                interviewQA={qa}
                                onClick={handleCardClick}
                                onReportClick={handleReportClick} // Pass handleReportClick down to ReportCard
                                isSelected={selectedCard && qa.id === selectedCard.id}
                                selectedMuiCardId={selectedMuiCardId} // Pass selected MuiCard Id down to ReportCard
                                demo={demo}
                            />
                        ))}
                    </div>
                </Grid>
                <Grid item xs={9} sx={{pt: "5px", pr: "20px", pl: "20px"}}>
                    <div style={{
                        maxHeight: 'calc(100vh - 64px)', // Subtract margin
                        overflowY: 'auto',
                    }}>
                    {demo  ? (
                        <Box sx={{border: "2px solid", px: "5px", my: "5px", py: "8px"}}>
                            <Typography variant="h4" display="block">
                                SAMPLE REPORT - Demo Mode
                            </Typography>
                            <Typography variant="body1" display="block" align="left" sx={{pb: "1rem"}}>
                                First, click on that card to the left. That's a saved Story from the Story Editor, and represents a real answer to a real potential interview question. This candidate was targeting a Senior SW Manager role (we have many roles you can target).
                            </Typography>
                            <Typography variant="body1" display="block" align="left" sx={{pb: "1rem"}}>
                                Next, click on one of the Evaluations from the VP Engineering or the SW Dev Manager (these are two of our available interviewer personas). Each persona provides different feedback.
                            </Typography>
                            <Typography variant="body1" display="block" align="left" sx={{pb: "1rem"}}>
                                We built this engine around our proprietary interview feedback process. Note that even with a small amount of content (the Your Answer section) customers get near instantaneous and extensive feedback on demand.
                            </Typography>
                            <Typography variant="body1" display="block" align="left">
                                To see how much Loopalla can improve your interview prep, read through the Quick Analysis, Overall Quality, Follow Up Qs, Rewritten Answer, or Answer Comparisons below. When you are ready to get started, create your account. You don't pay until you start performing Evaluations.
                            </Typography>
                        </Box>
                    ) : (
                        <></>
                    )}
                        {selectedReport && (
                            <>
                            {/* checking to make sure we are in demo state, and if so, surrounding the content with an explainer box, otherwise not */}
                                {threadLoop}
                                <ButtonGroup sx={{mt: "10px", mb:"15px", outline: "none"}}>
                                    {Object.entries(evaluations).map(([key, value]) => (
                                        <Button
                                            key={key}
                                            onClick={() => handleButtonClick(key)}
                                            style={{ 
                                                backgroundColor: key === selectedFilter ? '#e57373' : '#ef9a9a',
                                                color: 'black',
                                                fontWeight: key === selectedFilter ? 'bold' : 'normal',
                                                outline: 'none',}}
                                        >
                                            {value.button}
                                        </Button>
                                    ))}
                                </ButtonGroup>
                                <Typography variant="h6" display="block">
                                    <div style={{ whiteSpace: 'pre-wrap' }}>
                                        {evaluations[selectedFilter] ? evaluations[selectedFilter]["heading"] : selectedFilter}
                                    </div>
                                </Typography>
                                <Typography variant="body1" align="left" display="block" component="div" sx={{mt:"10px"}}>
                                    <div style={{ whiteSpace: 'pre-wrap' }}>
                                        {missingFields.includes(selectedFilter)
                                            ? getRandomStillWorkingMessage() + stillworking_finish                                        
                                            : selectedReport[selectedFilter]}
                                    </div>
                                </Typography>
                            </>
                        )}
                    </div>
                </Grid>
            </Grid>
        )
    }

    return (
        <>
            {/* {route === 'authenticated' ? renderCustomer() : "loading InterviewQAReport..."} */}
            {renderCustomer()}
        </>
    )
}

export default InterviewQAReport