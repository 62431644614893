import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Grid, Typography, Box, Button } from '@mui/material'

const illustrationNameBegin = "../images/illustrations/"

const data = [
    {
        title: 'Craft Compelling Stories',
        imageUrl: illustrationNameBegin + 'ideas_in_air.svg',
        description: 'As a tech professional seeking your next big opportunity, you understand the importance of sharing impactful stories during interviews. It\'s crucial to showcase your expertise, skills, and ability to excel in your desired role. Loopalla helps you refine and structure your interview stories, ensuring you stand out from the competition.',
    },
    {
        title: 'Choose the Perfect Interviewer        ',
        imageUrl: illustrationNameBegin + 'connecting_teams.svg',
        description: 'Imagine receiving feedback from a diverse range of personas, tailored to the specific role you\'re targeting. Loopalla makes this possible by allowing you to select an AI-powered interviewer that evaluates your responses based on the desired traits and experiences for your dream job. It\'s like having a personal interview coach without the hefty price tag.',
    },
    {
        title: 'Benefit from Direct Feedback',
        imageUrl: illustrationNameBegin + 'mind_map.svg',
        description: 'Discover the difference Loopalla\'s unique AI-driven approach brings to your interview preparation. Our advanced language model, built upon a 25-year career of feedback and evaluation expertise, goes beyond simple word changes or minor story additions. By analyzing your interview stories and offering comprehensive, in-depth feedback, Loopalla empowers you to polish your answers and effectively structure your stories, all at an affordable price.',
    },
]

function HowItWorks () {
    function handleCTAClick() {
        navigate("/userauth")
    }

    function handleDemoClick() {
        navigate("/demo")
    }

    const navigate = useNavigate()

    return (
        <>
            <Typography
                variant="h4"
                sx={{marginTop: '20px', marginBottom: '20px'}}
            >
                How Does Loopalla Work?
            </Typography>
            <Grid container justifyContent="space-around">
                {data.map(({ title, imageUrl, description }, index) => (
                    <Grid item xs={12} sm={4} key={index} style={{ marginTop: '15px', px: '20px' }}>
                        <Box style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography
                                variant="h6"
                                component="h3"
                                sx={{marginBottom: '10px'}}
                            >
                                {title}
                            </Typography>
                            <Box style={{ height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <img src={imageUrl} alt={title} style={{ maxWidth: 300 }} />
                            </Box>
                            <Typography
                                sx={{py: '10px', px: '20px', textAlign: 'justify'}}
                            >{description}</Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
            {/* <Typography
                variant="body1"
                textAlign="justify"
                sx={{fontSize: '1.15rem', fontWeight: 'bold', marginTop: '25px', paddingLeft: '30px', paddingRight: '30px'}}
            >
                Your analysis will include: how well did you adhere to the question and target leadership principle, a depth analyss of your answer with strengths and weaknesses, a list of likely follow-on questions you didn't address, a complete rewrite of your answer with inclusions of recommendend sections of content that would make your answer better, and then a side by side analysis of why the reqrute is superior the the input answer.
            </Typography> */}
            <Button
                variant="contained"
                sx={{
                    marginTop: '20px',
                    mx: "20px",
                    width: '220px',
                    backgroundColor: "#ae3737",
                    '&:hover': {
                        backgroundColor: "#8d2c2c"}
                ,}}
                onClick={handleCTAClick}
            >
                Start Using Loopalla
            </Button>
            <Button
                variant="contained"
                sx={{
                    marginTop: '20px',
                    mx: "20px",
                    width: '220px',
                    backgroundColor: "#ae3737",
                    '&:hover': {
                        backgroundColor: "#8d2c2c"}
                ,}}
                onClick={handleDemoClick}
            >
                Demo: Story Evaluation
            </Button>
        </>
    )
}

export default HowItWorks