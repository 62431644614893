//This query is being retained for historical record - I did not have ability to do server filtering and will do on the client
export const getSimulatedInterviewQAs = /* GraphQL */ `
    query GetSimulatedInterviewQAs($candidateid: ID!) {
        interviewqaByQaType(qatype: "simulated", candidateid: $candidateid) {
        items {
            id
            candidateid
            friendlyname
            lockedstatus
            qatype
            createdAt
            updatedAt
            owner
        }
        nextToken
        }
    }
`;

export const interviewqaByQaTypeWithThreads = /* GraphQL */ `
    query InterviewqaByQaType(
        $qatype: String!
        $candidateid: ModelIDKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelInterviewQAFilterInput
        $limit: Int
        $nextToken: String
    ) {
        interviewqaByQaType(
        qatype: $qatype
        candidateid: $candidateid
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
        ) {
        items {
            id
            candidateid
            friendlyname
            lockedstatus
            qatype
            interviewqathreads {
                items {
                    id
                    content
                    sequence
                }
                nextToken
            }
            reviewedpreparedanswers{
                items {
                    id
                    interviewqaid
                    persona {
                        id
                        role
                    }
                    targetrole {
                        id
                        role
                    }
                }
                nextToken
            }
        }
        nextToken
        }
    }
`;

export const getLeadershipPrincipleById = /* GraphQL */ `
    query GetLeadershipPrincipleById($id: ID!) {
        getLeadershipPrinciple(id: $id) {
        lp
        lpdescription
        }
    }
`;

export const interviewqaWithThreadAndReviewed = /* GraphQL */ `
    query InterviewqaWithThreadAndReviewed(
        $candidateid: ID!
        $id: ModelIDKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelInterviewQAFilterInput
        $limit: Int
        $nextToken: String
    ) {
        interviewqaByCandidateId(
            candidateid: $candidateid
            id: $id
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
        items {
            id
            candidate {
                id
                createdAt
                updatedAt
                owner
            }
            candidateid
            friendlyname
            interviewqathreads {
            items {
                id
                interviewqaid
                content
                sequence
                createdAt
                updatedAt
                owner
            }
            nextToken
            }
            lockedstatus
            qatype
            reviewedpreparedanswers(sortDirection: DESC, limit: $limit) {
            items {
                id
                interviewqaid
                personaid
                targetroleid
                targetlpid
                gptmodel
                gptlpadherence
                gptansweranalysis
                gptrewrite
                gptsuggestedquestions
                gptcritique
                completion_tokens
                prompt_tokens
                createdAt
                updatedAt
                owner
            }
            nextToken
            }
            createdAt
            updatedAt
            owner
        }
        nextToken
        }
    }
`;