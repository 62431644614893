import React, { useState, useEffect } from 'react'

import { Card as MuiCard, CardActionArea, Typography, Box } from '@mui/material'

import { API, graphqlOperation } from 'aws-amplify'

import { getPersona, getTargetRole } from '../graphql/queries'

import demoData from '../data/DemoData.js'

function ReportCard({ interviewQA, onClick, onReportClick, isSelected, selectedMuiCardId, demo }) {
    const [reports, setReports] = useState([])

    useEffect(() => {
        if (isSelected) {
            fetchReportData()
        } else {
            setReports([])
        }
    }, [isSelected])

    async function fetchReportData() {
        if (demo) {
            const demoReportData = demoData.interviewQAs.data.interviewqaByCandidateId.items[0]

            const reports = await Promise.all(
                demoReportData.reviewedpreparedanswers.items.map(async (report) => {
                    const rolename = await fetchRole(report.targetroleid)
                    const personaname = await fetchPersona(report.personaid)
                    return {
                        ...report,
                        targetrole: rolename,
                        persona: personaname
                    }
                })
            )

            setReports(reports)
        }
            else {
            const reports = await Promise.all(
                interviewQA.reviewedpreparedanswers.items.map(async (report) => {
                    const rolename = await fetchRole(report.targetroleid)
                    const personaname = await fetchPersona(report.personaid)
                    return {
                        ...report,
                        targetrole: rolename,
                        persona: personaname
                    }
                })
            )

            setReports(reports)
            console.log("LOGGING: REPORTCARD: user reports", reports)
        }
    }

    async function fetchRole(id) {
        if (demo) {
            const roleData = demoData.TargetRoles.filter(role => role.id === id)[0]
            return roleData.role
        }
        else {
            const roleData = await API.graphql(
                graphqlOperation(getTargetRole, { id: id })
            )
            const role = roleData.data.getTargetRole
            return role.role
        }
    }

    async function fetchPersona(id) {
        if (demo) {
            const personaData = demoData.personas.filter(persona => persona.id === id)[0]
            return personaData.role
        }
        else {
            const personaData = await API.graphql(
                graphqlOperation(getPersona, { id: id })
            )
            const persona = personaData.data.getPersona
            return persona.role
        }
    }

    return (
        <MuiCard sx={{
            background: isSelected ? '#e57373' : '#ef9a9a',
            mt: "12px",
            ml: "10px"
        }}>
            <CardActionArea onClick={isSelected ? null : () => onClick(interviewQA)}>
                <Typography variant="body1" component="div">
                    {interviewQA.friendlyname}
                </Typography>
            </CardActionArea>
            {isSelected && (
                <Box sx={{mr: "10px"}}>
                    {reports.map(report => (
                        <CardActionArea
                            key={report.id}
                            onClick={report.id === selectedMuiCardId ? null : () => onReportClick(report)}
                            sx={{
                                mt: "12px",
                                ml: "10px",
                                padding: "4px",
                                background: report.id === selectedMuiCardId ? '#ef9a9a': '#e57373',
                                outline: "white solid 2px"
                            }}
                        >
                            <Typography variant="subtitle2" display="block" align="left" component="div">Role: {report.targetrole}</Typography>
                            <Typography variant="subtitle2" display="block" align="left" component="div">Interviewer: {report.persona}</Typography>
                        </CardActionArea>
                    ))}
                </Box>
            )}
        </MuiCard>
    )
}

export default ReportCard