import React from 'react'

import { useNavigate } from 'react-router-dom'
import { useMediaQuery, Hidden } from '@mui/material'

import { Grid, Typography, Box, Button } from '@mui/material'

const illustrationNameBegin = "../images/illustrations/"

function HomeStep({step}) {
    const buttonAlignment = step.index % 2 === 0 ? "flex-end" : "flex-start";
    const imagePosition = step.index % 2 === 0 ? "flex-start" : "flex-end";

    return (
        <Grid container spacing={2} direction={step.index % 2 === 0 ? 'row-reverse' : 'row'} sx={{mb: "20px"}}>
            <Grid item xs={12} sm={8}>
                <Grid container direction="column">
                    <Grid item>
                        <Typography variant="h3" sx={{mb: "10px"}}>{step.title}</Typography>
                        <Typography variant="body1" align="left" sx={{px: "35px", mb: "25px"}}>{step.description}</Typography>
                    </Grid>
                    <Grid item container justifyContent={buttonAlignment}>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: "#ae3737",
                                width: "200px",
                                color: "white",
                                '&:hover': {
                                    backgroundColor: "#8d2c2c",
                                },
                                mx: "35px",
                            }}
                            onClick={step.navigate}
                        >
                            {step.buttonText}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={4} display={{ xs: 'none', sm: 'block' }}>
                <img src={step.imageUrl} alt={step.title} style={{maxWidth: "300px", maxHeight: "300px", marginRight: "10px", marginLeft: "10px"}}/>
            </Grid>
        </Grid>
    )
}

function HomepageNextSteps() {
    const navigate = useNavigate()
    const mobileView = useMediaQuery(theme => theme.breakpoints.down('sm')) // detects mobile view

    const data = [
        {
            title: 'Create a New Story',
            imageUrl: illustrationNameBegin + 'ideas_in_air.svg',
            description: 'We also know that many people who are preparing for interviews have created documents with their stories fully written out. If you have done that, you are ahead of the curve. You can easily copy and paste in the question you were answering and your story.',
            buttonText: "Start Writing",
            navigate: () => navigate("/interviewqa"),
        },        {
            title: 'Upload a Resume',
            imageUrl: illustrationNameBegin + 'pending_approval.svg',
            description: 'It can be frustrating when you are trying to prepare for an interview and you think to yourself \"I don\'t have any stories!\" We got you! If you upload a resume, our engine will process it to make sure we can use it. If we can, you can auto-generate stories.',
            buttonText: "Upload Resume",
            navigate: () => navigate("/profile"),
        },
        {
            title: 'Auto-Generate a Story',
            imageUrl: illustrationNameBegin + 'chat_bot.svg',
            description: 'Starting from scratch can be overwhelming. Our engine can use your resume and generate likely interview questions based on the experiences on your resume. That\'s cool, but we knew we could do better. Not only do we generate a targeted question, we will provide you a detailed outline of what your answer should look like, which you can then edit and craft into your own words.',
            buttonText: "Try Auto-Gen",
            navigate: () => navigate("/interviewqa"),
        },
        {
            title: 'Evaluate Your Stories',
            imageUrl: illustrationNameBegin + 'interview.svg',
            description: 'Now the cool part. You click the button to run an evaluation. We\'ll ask you to tell us what role you are targeting and by which persona you want to be evaluated. Please keep in mind, at this time we are focused on tech roles. If you need a role or persona added please email or DM us on Twitter. We will then evaluate your story against the persona with the analysis focusing on your target role and produce an extensive report on how you did. This information will absolutely help you improve your stories and help you be more memorable and impressive at your interview.',
            buttonText: "Evaluate a Story",
            navigate: () => navigate("/interviewqa"),
        },
        {
            title: 'Review Your Evaluations',
            imageUrl: illustrationNameBegin + 'thought_process.svg',
            description: 'The process to evaluate your stories is fast, but not instant. When your evaluations are ready you can review the in depth analysis provided by each persona you selected to evaluate your story. It can be useful to have multiple evaluations run against your story from various personas to understand recurring themes and areas of improvement, as well as items specific to what an interviewer of a particular persona might identify that is unique to their role or level.',
            buttonText: "See Your Evaluations",
            navigate: () => navigate("/reports"),
        },
            
    ]

    function imageRight(item){
        if(mobileView) { // render only text for mobile view
            return itemHtml(item)
        }

        // for non-mobile views
        return (
            <Grid container>
                <Grid item xs={8}>
                    {itemHtml(item)}
                </Grid>
                <Grid item xs={4}>
                    {imageHtml(item)}
                </Grid>
            </Grid>
        )
    }

    function imageLeft(item){
        if(mobileView) { // render only text for mobile view
            return itemHtml(item)
        }

        // for non-mobile views
        return (
            <Grid container>
                <Grid item xs={4}>
                    {imageHtml(item)}
                </Grid>
                <Grid item xs={8}>
                    {itemHtml(item)}
                </Grid>
            </Grid>
        )
    }

    function imageHtml(item) {
        if(mobileView) return null // do not render image on mobile view

        return (
            <>
                <img src={item.imageUrl} alt={item.title} style={{maxWidth: "300px", maxHeight: "300px"}} />
            </>
        )
    }

    function itemHtml(item, index) {
        return (
            <Box display="flex" flexDirection="column">
                <Typography variant="h3" sx={{mb: "10px"}}>{item.title}</Typography>
                <Box display="flex" flexDirection="column" sx={{px: "35px", flex: 1}}>
                    <Typography variant="body1" align="left" >{item.description}</Typography>
                    <Box 
                        display="flex"
                        justifyContent={index % 2 === 0 ? 'flex-end' : 'flex-start'} 
                        sx={{mt: '20px'}}
                    >
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: "#ae3737",
                                width: "240px",
                                color: "white",
                                '&:hover': {
                                    backgroundColor: "#8d2c2c",
                                },
                            }}
                            onClick={item.navigate}
                        >
                            {item.buttonText}
                        </Button>
                    </Box>
                </Box>
            </Box>
        )
    }

    return (
        <Grid container>
            <Box width="100%" display="flex" justifyContent="center" alignItems="center">
                <Typography variant="h2" align="center" gutterBottom>
                    What Can You Do Now?
                </Typography>
            </Box>
            {data.map((step, index) => (
                <Box key={index} sx={{my: "25px"}}>
                    <HomeStep step={{...step, index}}/>
                </Box>
            ))}
        </Grid>
    )
}

export default HomepageNextSteps