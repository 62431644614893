import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Container, Divider } from '@mui/material'

import HeroTopLandingPage from './HeroTopLandingPage'
import FeaturesCarousel from './FeaturesCarousel'
import CustomerTestimonial from './CustomerTestimonial'
import HowItWorks from './HowItWorks'
import FaqItems from './FaqItems'
// import CustomerSubscription from './CustomerSubscription'

function LandingPage() {
    function handleCTAClick() {
        navigate("/userauth")
    }
    const navigate = useNavigate()

    return (
        <>
            {/* <HeroTopLandingPage /> */}
            <FeaturesCarousel />
            <CustomerTestimonial />
            <HowItWorks />
            <Container
                sx={{ paddingRight: '600px', paddingLeft: '20px'}}
            >
                <Divider
                    sx={{marginTop: '30px', height: '5px', backgroundColor: '#ae3737'}}
                />
            </Container>
            <FaqItems />
        </>
    )
}

export default LandingPage