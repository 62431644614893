/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCandidate = /* GraphQL */ `
  query GetCandidate($id: ID!) {
    getCandidate(id: $id) {
      id
      interviewqas {
        items {
          id
          candidateid
          friendlyname
          lpid
          lockedstatus
          qatype
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCandidates = /* GraphQL */ `
  query ListCandidates(
    $filter: ModelCandidateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCandidates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        interviewqas {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getInterviewQA = /* GraphQL */ `
  query GetInterviewQA($id: ID!) {
    getInterviewQA(id: $id) {
      id
      candidate {
        id
        interviewqas {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      candidateid
      friendlyname
      interviewqathreads {
        items {
          id
          interviewqaid
          content
          sequence
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      lpid
      lockedstatus
      qatype
      reviewedpreparedanswers {
        items {
          id
          interviewqaid
          targetroleid
          personaid
          targetlpid
          gptmodel
          gptlpadherence
          gptansweranalysis
          gptrewrite
          gptsuggestedquestions
          gptcritique
          completion_tokens
          prompt_tokens
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listInterviewQAS = /* GraphQL */ `
  query ListInterviewQAS(
    $filter: ModelInterviewQAFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInterviewQAS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        candidate {
          id
          createdAt
          updatedAt
          owner
        }
        candidateid
        friendlyname
        interviewqathreads {
          nextToken
        }
        lpid
        lockedstatus
        qatype
        reviewedpreparedanswers {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getInterviewQAThread = /* GraphQL */ `
  query GetInterviewQAThread($id: ID!) {
    getInterviewQAThread(id: $id) {
      id
      interviewqa {
        id
        candidate {
          id
          createdAt
          updatedAt
          owner
        }
        candidateid
        friendlyname
        interviewqathreads {
          nextToken
        }
        lpid
        lockedstatus
        qatype
        reviewedpreparedanswers {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      interviewqaid
      content
      sequence
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listInterviewQAThreads = /* GraphQL */ `
  query ListInterviewQAThreads(
    $filter: ModelInterviewQAThreadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInterviewQAThreads(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        interviewqa {
          id
          candidateid
          friendlyname
          lpid
          lockedstatus
          qatype
          createdAt
          updatedAt
          owner
        }
        interviewqaid
        content
        sequence
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getReviewedPreparedAnswer = /* GraphQL */ `
  query GetReviewedPreparedAnswer($id: ID!) {
    getReviewedPreparedAnswer(id: $id) {
      id
      interviewqa {
        id
        candidate {
          id
          createdAt
          updatedAt
          owner
        }
        candidateid
        friendlyname
        interviewqathreads {
          nextToken
        }
        lpid
        lockedstatus
        qatype
        reviewedpreparedanswers {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      interviewqaid
      targetrole {
        id
        role
        level
        rootjob
        targetlps
        roledescription
        reviewedpreparedanswers {
          nextToken
        }
        createdAt
        updatedAt
      }
      targetroleid
      persona {
        id
        role
        persona
        targettraits
        disc
        discprofile
        reviewedpreparedanswers {
          nextToken
        }
        createdAt
        updatedAt
      }
      personaid
      targetlp {
        id
        lp
        lpdescription
        questions {
          nextToken
        }
        reviewedpreparedanswers {
          nextToken
        }
        createdAt
        updatedAt
      }
      targetlpid
      gptmodel
      gptlpadherence
      gptansweranalysis
      gptrewrite
      gptsuggestedquestions
      gptcritique
      completion_tokens
      prompt_tokens
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listReviewedPreparedAnswers = /* GraphQL */ `
  query ListReviewedPreparedAnswers(
    $filter: ModelReviewedPreparedAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReviewedPreparedAnswers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        interviewqa {
          id
          candidateid
          friendlyname
          lpid
          lockedstatus
          qatype
          createdAt
          updatedAt
          owner
        }
        interviewqaid
        targetrole {
          id
          role
          level
          rootjob
          targetlps
          roledescription
          createdAt
          updatedAt
        }
        targetroleid
        persona {
          id
          role
          persona
          targettraits
          disc
          discprofile
          createdAt
          updatedAt
        }
        personaid
        targetlp {
          id
          lp
          lpdescription
          createdAt
          updatedAt
        }
        targetlpid
        gptmodel
        gptlpadherence
        gptansweranalysis
        gptrewrite
        gptsuggestedquestions
        gptcritique
        completion_tokens
        prompt_tokens
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getCustomerResumeProfile = /* GraphQL */ `
  query GetCustomerResumeProfile($pk: ID!, $sk: AWSDateTime!) {
    getCustomerResumeProfile(pk: $pk, sk: $sk) {
      pk
      sk
      s3filename
      isactive
      isresume
      processedbygpt
      extracttext
      completion_tokens
      prompt_tokens
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCustomerResumeProfiles = /* GraphQL */ `
  query ListCustomerResumeProfiles(
    $pk: ID
    $sk: ModelStringKeyConditionInput
    $filter: ModelCustomerResumeProfileFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCustomerResumeProfiles(
      pk: $pk
      sk: $sk
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        sk
        s3filename
        isactive
        isresume
        processedbygpt
        extracttext
        completion_tokens
        prompt_tokens
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getOnboardResumeRequest = /* GraphQL */ `
  query GetOnboardResumeRequest($pk: ID!, $sk: AWSDateTime!) {
    getOnboardResumeRequest(pk: $pk, sk: $sk) {
      pk
      sk
      sk_resume
      interviewqaid
      gptoutline
      completion_tokens
      prompt_tokens
      isprocessed
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listOnboardResumeRequests = /* GraphQL */ `
  query ListOnboardResumeRequests(
    $pk: ID
    $sk: ModelStringKeyConditionInput
    $filter: ModelOnboardResumeRequestFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOnboardResumeRequests(
      pk: $pk
      sk: $sk
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        sk
        sk_resume
        interviewqaid
        gptoutline
        completion_tokens
        prompt_tokens
        isprocessed
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getCustomerSubscription = /* GraphQL */ `
  query GetCustomerSubscription($id: ID!) {
    getCustomerSubscription(id: $id) {
      id
      stripe_id
      subscription_level
      subscription_interval
      subscription_interval_count
      subscription_id
      plan_id
      plan_active
      start_date
      status
      next_billing_date
      last_webhook_received
      last_webhook_event
      createdAt
      updatedAt
    }
  }
`;
export const listCustomerSubscriptions = /* GraphQL */ `
  query ListCustomerSubscriptions(
    $filter: ModelCustomerSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        stripe_id
        subscription_level
        subscription_interval
        subscription_interval_count
        subscription_id
        plan_id
        plan_active
        start_date
        status
        next_billing_date
        last_webhook_received
        last_webhook_event
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const interviewqaByCandidateId = /* GraphQL */ `
  query InterviewqaByCandidateId(
    $candidateid: ID!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInterviewQAFilterInput
    $limit: Int
    $nextToken: String
  ) {
    interviewqaByCandidateId(
      candidateid: $candidateid
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        candidate {
          id
          createdAt
          updatedAt
          owner
        }
        candidateid
        friendlyname
        interviewqathreads {
          nextToken
        }
        lpid
        lockedstatus
        qatype
        reviewedpreparedanswers {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const interviewqaByLp = /* GraphQL */ `
  query InterviewqaByLp(
    $lpid: ID!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInterviewQAFilterInput
    $limit: Int
    $nextToken: String
  ) {
    interviewqaByLp(
      lpid: $lpid
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        candidate {
          id
          createdAt
          updatedAt
          owner
        }
        candidateid
        friendlyname
        interviewqathreads {
          nextToken
        }
        lpid
        lockedstatus
        qatype
        reviewedpreparedanswers {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const interviewqaByQaType = /* GraphQL */ `
  query InterviewqaByQaType(
    $qatype: String!
    $candidateid: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInterviewQAFilterInput
    $limit: Int
    $nextToken: String
  ) {
    interviewqaByQaType(
      qatype: $qatype
      candidateid: $candidateid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        candidate {
          id
          createdAt
          updatedAt
          owner
        }
        candidateid
        friendlyname
        interviewqathreads {
          nextToken
        }
        lpid
        lockedstatus
        qatype
        reviewedpreparedanswers {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const interviewQAThreadsByInterviewqaidAndId = /* GraphQL */ `
  query InterviewQAThreadsByInterviewqaidAndId(
    $interviewqaid: ID!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInterviewQAThreadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    interviewQAThreadsByInterviewqaidAndId(
      interviewqaid: $interviewqaid
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        interviewqa {
          id
          candidateid
          friendlyname
          lpid
          lockedstatus
          qatype
          createdAt
          updatedAt
          owner
        }
        interviewqaid
        content
        sequence
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const threadBySequence = /* GraphQL */ `
  query ThreadBySequence(
    $sequence: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelInterviewQAThreadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    threadBySequence(
      sequence: $sequence
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        interviewqa {
          id
          candidateid
          friendlyname
          lpid
          lockedstatus
          qatype
          createdAt
          updatedAt
          owner
        }
        interviewqaid
        content
        sequence
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const reviewedPreparedAnswerbyCandidateId = /* GraphQL */ `
  query ReviewedPreparedAnswerbyCandidateId(
    $interviewqaid: ID!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReviewedPreparedAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reviewedPreparedAnswerbyCandidateId(
      interviewqaid: $interviewqaid
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        interviewqa {
          id
          candidateid
          friendlyname
          lpid
          lockedstatus
          qatype
          createdAt
          updatedAt
          owner
        }
        interviewqaid
        targetrole {
          id
          role
          level
          rootjob
          targetlps
          roledescription
          createdAt
          updatedAt
        }
        targetroleid
        persona {
          id
          role
          persona
          targettraits
          disc
          discprofile
          createdAt
          updatedAt
        }
        personaid
        targetlp {
          id
          lp
          lpdescription
          createdAt
          updatedAt
        }
        targetlpid
        gptmodel
        gptlpadherence
        gptansweranalysis
        gptrewrite
        gptsuggestedquestions
        gptcritique
        completion_tokens
        prompt_tokens
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const customerResumeProfilesByFilename = /* GraphQL */ `
  query CustomerResumeProfilesByFilename(
    $s3filename: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerResumeProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerResumeProfilesByFilename(
      s3filename: $s3filename
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        sk
        s3filename
        isactive
        isresume
        processedbygpt
        extracttext
        completion_tokens
        prompt_tokens
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const onboardResumeRequestBySk_Resume = /* GraphQL */ `
  query OnboardResumeRequestBySk_Resume(
    $sk_resume: AWSDateTime!
    $sortDirection: ModelSortDirection
    $filter: ModelOnboardResumeRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    onboardResumeRequestBySk_Resume(
      sk_resume: $sk_resume
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        sk
        sk_resume
        interviewqaid
        gptoutline
        completion_tokens
        prompt_tokens
        isprocessed
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const onboardResumeRequestByInterviewQaId = /* GraphQL */ `
  query OnboardResumeRequestByInterviewQaId(
    $interviewqaid: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOnboardResumeRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    onboardResumeRequestByInterviewQaId(
      interviewqaid: $interviewqaid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        sk
        sk_resume
        interviewqaid
        gptoutline
        completion_tokens
        prompt_tokens
        isprocessed
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const customerSubscriptionByStripeId = /* GraphQL */ `
  query CustomerSubscriptionByStripeId(
    $stripe_id: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerSubscriptionByStripeId(
      stripe_id: $stripe_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        stripe_id
        subscription_level
        subscription_interval
        subscription_interval_count
        subscription_id
        plan_id
        plan_active
        start_date
        status
        next_billing_date
        last_webhook_received
        last_webhook_event
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPersona = /* GraphQL */ `
  query GetPersona($id: ID!) {
    getPersona(id: $id) {
      id
      role
      persona
      targettraits
      disc
      discprofile
      reviewedpreparedanswers {
        items {
          id
          interviewqaid
          targetroleid
          personaid
          targetlpid
          gptmodel
          gptlpadherence
          gptansweranalysis
          gptrewrite
          gptsuggestedquestions
          gptcritique
          completion_tokens
          prompt_tokens
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPersonas = /* GraphQL */ `
  query ListPersonas(
    $filter: ModelPersonaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPersonas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        role
        persona
        targettraits
        disc
        discprofile
        reviewedpreparedanswers {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byTargetPersona = /* GraphQL */ `
  query ByTargetPersona(
    $role: String!
    $sortDirection: ModelSortDirection
    $filter: ModelPersonaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byTargetPersona(
      role: $role
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        role
        persona
        targettraits
        disc
        discprofile
        reviewedpreparedanswers {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTargetRole = /* GraphQL */ `
  query GetTargetRole($id: ID!) {
    getTargetRole(id: $id) {
      id
      role
      level
      rootjob
      targetlps
      roledescription
      reviewedpreparedanswers {
        items {
          id
          interviewqaid
          targetroleid
          personaid
          targetlpid
          gptmodel
          gptlpadherence
          gptansweranalysis
          gptrewrite
          gptsuggestedquestions
          gptcritique
          completion_tokens
          prompt_tokens
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTargetRoles = /* GraphQL */ `
  query ListTargetRoles(
    $filter: ModelTargetRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTargetRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        role
        level
        rootjob
        targetlps
        roledescription
        reviewedpreparedanswers {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byTargetRole = /* GraphQL */ `
  query ByTargetRole(
    $role: String!
    $sortDirection: ModelSortDirection
    $filter: ModelTargetRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byTargetRole(
      role: $role
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        role
        level
        rootjob
        targetlps
        roledescription
        reviewedpreparedanswers {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byLevel = /* GraphQL */ `
  query ByLevel(
    $level: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelTargetRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byLevel(
      level: $level
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        role
        level
        rootjob
        targetlps
        roledescription
        reviewedpreparedanswers {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byRootJob = /* GraphQL */ `
  query ByRootJob(
    $rootjob: String!
    $sortDirection: ModelSortDirection
    $filter: ModelTargetRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byRootJob(
      rootjob: $rootjob
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        role
        level
        rootjob
        targetlps
        roledescription
        reviewedpreparedanswers {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLeadershipPrinciple = /* GraphQL */ `
  query GetLeadershipPrinciple($id: ID!) {
    getLeadershipPrinciple(id: $id) {
      id
      lp
      lpdescription
      questions {
        items {
          id
          questiontext
          lpid
          createdAt
          updatedAt
        }
        nextToken
      }
      reviewedpreparedanswers {
        items {
          id
          interviewqaid
          targetroleid
          personaid
          targetlpid
          gptmodel
          gptlpadherence
          gptansweranalysis
          gptrewrite
          gptsuggestedquestions
          gptcritique
          completion_tokens
          prompt_tokens
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listLeadershipPrinciples = /* GraphQL */ `
  query ListLeadershipPrinciples(
    $filter: ModelLeadershipPrincipleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeadershipPrinciples(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lp
        lpdescription
        questions {
          nextToken
        }
        reviewedpreparedanswers {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byLpName = /* GraphQL */ `
  query ByLpName(
    $lp: String!
    $sortDirection: ModelSortDirection
    $filter: ModelLeadershipPrincipleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byLpName(
      lp: $lp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lp
        lpdescription
        questions {
          nextToken
        }
        reviewedpreparedanswers {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuestion = /* GraphQL */ `
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      questiontext
      leadershipprinciple {
        id
        lp
        lpdescription
        questions {
          nextToken
        }
        reviewedpreparedanswers {
          nextToken
        }
        createdAt
        updatedAt
      }
      lpid
      createdAt
      updatedAt
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        questiontext
        leadershipprinciple {
          id
          lp
          lpdescription
          createdAt
          updatedAt
        }
        lpid
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const questionsByLpidAndId = /* GraphQL */ `
  query QuestionsByLpidAndId(
    $lpid: ID!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionsByLpidAndId(
      lpid: $lpid
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        questiontext
        leadershipprinciple {
          id
          lp
          lpdescription
          createdAt
          updatedAt
        }
        lpid
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
