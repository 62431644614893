import React from 'react'

import { Typography } from '@mui/material'

function UserProfileInfo ({ user }) {
    return (
        <div>
            <Typography variant="h6">Email: {user.attributes.email}</Typography>
            <Typography variant="subtitle2">User ID: {user.username}</Typography>
        </div>
    )
}

export default UserProfileInfo