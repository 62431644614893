import React, { useState, useEffect } from 'react'

import { Typography } from '@mui/material'

import { API, graphqlOperation } from 'aws-amplify'
import { useAuthenticator } from '@aws-amplify/ui-react'

import { getCustomerSubscription } from '../graphql/queries'
import { v4 as uuidv4 } from 'uuid'

function CustomerSubscription() {
    const { user } = useAuthenticator((context) => [context.user])
    const [subscription, setSubscription] = useState(null)
    const [subscriptionLevel, setSubscriptionLevel] = useState(null)

    useEffect(() => {
        async function fetchSubscriptionData () {
            try {
                const response = await API.graphql(
                    graphqlOperation(getCustomerSubscription, {
                        id: user.username,
                    }),
                )
                setSubscription(response.data.getCustomerSubscription)

                if (response.data.getCustomerSubscription.subscription_interval_count === 1) {
                    setSubscriptionLevel('Monthly')
                }
                else if (response.data.getCustomerSubscription.subscription_interval_count === 3) {
                    setSubscriptionLevel('Monhthly, Paid Quarterly')
                }
            } catch (error) {
                console.error('Error fetching subscription data:', error)
            }
        }
        

        if (user) {
            fetchSubscriptionData()
        }
    }, [user])

    const clientReference = user ? user.username : uuidv4()

    return (
        <div>
            <Typography variant="h6">CustomerSubscription</Typography>
            {subscription === null ? (
                <stripe-pricing-table
                    pricing-table-id="prctbl_1NAxa3FjnYSZ1pE3C53rnyMZ"
                    publishable-key="pk_test_51JrppnFjnYSZ1pE30oi5RzXQKxHLNdhiVbS0vzI9jlSGZJTqZcqbT0dPRDHCXSMfWJqOD14SpUmyr4Oae5bnYP5K00w6qGMHwo"
                    client-reference-id={clientReference}
                ></stripe-pricing-table>
            ) : (
                <div>
                    <Typography>
                        Subscription Level: {subscriptionLevel}
                    </Typography>
                    <Typography>
                        Next Billing Date: {subscription.next_billing_date}
                    </Typography>
                </div>
            )}
        </div>
    )
}

export default CustomerSubscription