import React from "react"

import { Box } from "@mui/material"

import { useAuthenticator } from '@aws-amplify/ui-react'

import HomepageNextSteps from '../components/HomepageNextSteps'
import LandingPage from '../components/LandingPage'

function Home() {
    const { route } = useAuthenticator((context) => [context.route])

    function renderCustomer() {
        return (
            <Box sx={{
                    maxHeight: 'calc(100vh - 64px)', // 64px to compensate for the height of the Navbar
                    overflowY: 'auto'
                }}
            >
                <HomepageNextSteps />
            </Box>
        )
    }

    function renderUnknownUser() {
        return (
            <Box sx={{
                maxHeight: 'calc(100vh - 64px)', // 64px to compensate for the height of the Navbar
                overflowY: 'auto'
                }}
            >
                <LandingPage />
            </Box>
        )
    }

    return (
        <>
            { route === 'authenticated' ? renderCustomer() : renderUnknownUser() }
        </>
    )
}

export default Home