import React from 'react'

import { Typography, Grid, Box } from '@mui/material'


function CustomerTestimonial() {
    // Replace this array with the actual logo URLs
    const logoNameBegin = "../images/logos/"
    const logoNameEnd = "-Logo-small.png"
    const logoUrls = [
        'Microsoft',
        'Meta',
        'Amazon',
        'Google',
        'Netflix',
        'TikTok',
    ]

    return (
        <>
            <Box
                sx={{
                    marginTop: '50px',
                    paddingBottom: '20px',
                    bgcolor: 'lightgray',
                }}
            >
                <Typography
                    variant="h4"
                    align="center"
                    sx={{paddingTop: '15px', paddingBottom: '10px'}}
                >
                    Our customers routinely land jobs at these companies:
                </Typography>
                <Grid 
                    container 
                    spacing={2} 
                    justifyContent="center"
                    alignItems="center"
                >
                    {logoUrls.map((url) => (
                            <Grid item xs={4} key={url}>
                            <img src={logoNameBegin + url + logoNameEnd} width={150} />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </>
    )
}

export default CustomerTestimonial