import React, { useState } from 'react'

import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { DataArray } from '@mui/icons-material'

const faqs = [
    {
        question: 'Does this work?',
        answer: 'Yes. Believe me, I was as skeptical as you probably are right now. I spent months going through prompts, API calls, and keeping extremely copious notes on the results. I wanted to recreate the process I developed over my multiple decades of work experience at Microsoft, Amazon, and other tech companies to deliver an amazing analytic tool to customers so that they can get out of their own way. Stop me if you have ever thought this: \"I know what I want to say, I just don\'t know how to say it.\" Cool. We solve that problem for you.',
    },
    {
        question: 'Is this better or different than a coach?',
        answer: 'Since the beginning of COVID, one of my main business pursuits was to re-invent how companies and candidates meet in the market. I started with interview coaching because it was something I could do well, and allowed me to DEEPLY understand the process and problems candidates have. I mean: DEEPLY UNDERSTAND. I can go on at length about the various common mistakes candidates make, but that doesn\'t help you right now. This SaaS application works tirelessly 24 hours a day to help you show up better in your job interviews.',
    },
    {
        question: 'Why did you build this?',
        answer: 'There were a couple of factors. I love helping people, but like most people my time doesn\'t scale very well. I love making the videos analyzing my customer interviews for YouTube, but to do a full coaching loop for each person is 3-4 hours of my time. I wanted to build a way to help more people. GPT and large language models (LLMs) came out and at first I was skeptical, but then I spent several months trying to really understand how they worked and what they could do. Then I got to work building. I hope you are as happy to use this as I am to deliver it to you.',
    },
    {
        question: 'What do I get with this service?',
        answer: 'Your analysis will include: how well did you adhere to the question and target leadership principle, a depth analysis of your answer with strengths and weaknesses, a list of likely follow-on questions you didn\'t address, a complete rewrite of your answer with inclusions of recommended sections of content that would make your answer better, and then a side by side analysis of why the rewrite is superior to the input answer.',
    },
    {
        question: 'What is an \'evaluation\'?',
        answer: 'Once you type in your interview story, you have the option to generate an analysis. For that you will need to select the target interviewer persona, and the role you are targeting. Then the AI goes off and does its thing. When you are a paying customer, you can generate an unlimited number of evaluations.',
    },
    {
        question: 'How long can I access my data?',
        answer: 'As long as you have your account. You pay for the services you use, but once an evaluation has occurred, that is your data and you can access it forever.',
    },
    {
        question: 'Do you offer any guarantees?',
        answer: 'You bet. If you pay, and try a couple of evaluations (meaning 3 or less) and you really don\'t like it, let me know. I will fully refund your money, and you can keep the evaluations. We\'re trusting you not to abuse this.'
    },
    {
        question: 'Is my data private?',
        answer: 'Yup. Totally. Everything is in the cloud, so there\'s that, but its all sitting behind authentication.',
    },
    {
        question: 'How much does it cost?',
        answer: 'The current pricing model (always subject to change) is $149 for unlimited use for 1 month, or $249 for 3 months. Why did we do it this way? Most people are only in their job search for 1-3 months. We didn\'t want to create another stealth monthly subscription you forget to turn off. Even if you stop paying, you have access to any completed evaluations.',
    }
]

const FaqItems = () => {
    const [expanded, setExpanded] = useState(false)

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    return (
        <Box sx={{marginTop: '30px'}}>
            <Typography
                variant="h3"
            >
                FAQ
            </Typography>
            {faqs.map((faq, index) => (
                <Accordion
                    key={index}
                    sx={{textAlign: 'left'}}
                    expanded={expanded === `panel${index}`}
                    onChange={handleChange(`panel${index}`)}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">{faq.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>{faq.answer}</Typography>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    )
}

export default FaqItems