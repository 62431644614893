import React, { useState, useCallback, useEffect } from 'react'

import { Typography } from '@mui/material'

import { useDropzone } from 'react-dropzone'

import { Storage, API, graphqlOperation } from 'aws-amplify'

import { createCustomerResumeProfile } from '../graphql/mutations'

const dropzoneStyle = (isDragAccept, isDragReject) => ({
    border: isDragAccept ? '2px solid green' : isDragReject ? '2px solid red' : '2px dashed lightblue',
    borderRadius: '5px',
    padding: '20px',
    textAlign: 'center',
    boxShadow: isDragReject ? '0 0 10px red' : '',
    width: '50%',
    margin: '0 auto'
})

function CustomerResumeDrop({ user, resumes, setResumes }) {
    const [uploading, setUploading] = useState(false)
    const [uploadSuccessMessage, setUploadSuccessMessage] = useState('')

    const onDrop = useCallback(async acceptedfiles => {
        setUploading(true)
        setUploadSuccessMessage('')
        try {
            const newResumes = await Promise.all(
                acceptedfiles.map(async file => {
                    const userid = user.username
                    const timestamp = new Date().toISOString()
                    const filename = `${userid}-${file.name.split('.')[0]}-${timestamp}.${file.name.split('.')[1]}`
                    await Storage.put(filename, file)
                    const input = {
                        pk: userid,
                        sk: timestamp,
                        s3filename: filename,
                        isactive: false,
                    }
                    const response = await API.graphql(graphqlOperation(createCustomerResumeProfile, { input }))
                    return response.data.createCustomerResumeProfile
                })
            )
            setResumes([...resumes, ...newResumes])
            setUploadSuccessMessage(`${acceptedfiles[0].name} successfully uploaded!`)
        } catch (error) {
            console.error('Error uploading files:', error)
        } finally {
            setUploading(false)
        }
    }, [user, resumes])

    useEffect(() => {
        if (uploadSuccessMessage) {
            const timer = setTimeout(() => {
                setUploadSuccessMessage('')
            }, 10000)
            return () => clearTimeout(timer)
        }
    }, [uploadSuccessMessage])

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        onDrop,
        accept: {
            //'application/pdf': ['.pdf'],
            //'text/plain': ['.txt'],
            'application/msword': ['.docx'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']
        }
    })

    function renderUploadStatus () {
        if (uploading) {
            return <Typography variant='subtitle2'>Uploading...</Typography>
        }
        if (uploadSuccessMessage) {
            return <Typography variant='subtitle2'>{uploadSuccessMessage}</Typography>
        }
        return null
    }

    return (
        <div>
            <br/>
            <Typography variant="h6">Upload Resumes Here for More Analysis Goodness</Typography>
            <div {...getRootProps()} style={dropzoneStyle(isDragAccept, isDragReject)}>
                <input {...getInputProps()} />
                <Typography variant='subtitle1'>{isDragReject ? 'Filetype not acceptable' : 'Drag your resume into this space; we currently only accept .DOCX files - if you have a .doc, please update to .DOCX'}</Typography>
                {renderUploadStatus()}
            </div>
            <Typography variant="subtitle2">
                If you need to convert your resume to .DOCX, you can use <a href="https://www.adobe.com/acrobat/online/pdf-to-word.html" target="_blank" rel="noreferrer">Adobe's Free PDF to Word Conversion Tool</a>.
            </Typography>
        </div>
    )
}

export default CustomerResumeDrop