import "./App.css"
import { Route, Routes } from "react-router-dom"

import { Amplify } from "aws-amplify"
import { Authenticator } from "@aws-amplify/ui-react"
import "@aws-amplify/ui-react/styles.css"

import Navbar from "./components/Navbar"

import Home from './pages/Home'
import Faq from './pages/Faq'

import RequireAuth from './pages/RequireAuth'
import UserAuth from './pages/UserAuth'

import Interviews from './pages/Interviews'
import InterviewQAReport from './pages/InterviewQAReport'
import UserProfile from './pages/UserProfile'
import ReportDemo from './pages/ReportDemo'

import awsExports from "./aws-exports"

Amplify.configure(awsExports)

function App() {
    return (
        <Authenticator.Provider>
            <div className="App" style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', overflow: 'hidden' }}>
                <div style={{ minHeight: '64px' }}>
                    <Navbar />
                </div>
                <div style={{ flexGrow: 1 }}>
                    <Routes>
                        <Route path="/userauth" element={
                                <UserAuth />
                        } />
                        <Route path="/faq" element={
                            <Faq />
                        } />
                        <Route path="/" element={
                            <Home />
                        } />
                        <Route path="/demo" element={
                            <ReportDemo />
                        } />
                        <Route path="/interviewqa" element={
                            <RequireAuth>
                                <Interviews qatype="prepared" />
                            </RequireAuth>
                        } />
                        <Route path="/reports" element={
                            <RequireAuth>
                                <InterviewQAReport />
                            </RequireAuth>
                        } />
                        <Route path="/profile" element={
                            <RequireAuth>
                                <UserProfile />
                            </RequireAuth>
                        } />
                    </Routes>
                </div>
            </div>
        </Authenticator.Provider>
    )
}

export default App