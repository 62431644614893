import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Card, CardContent, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Box, Button } from '@mui/material'

import { Lock, Delete, Person } from '@mui/icons-material'


function InterviewQACard({ item, isActive, onClick, onDelete, evaluators = [] }) {
    const [carddata, setCardData] = useState(item)
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)
    const navigate = useNavigate()

    function handleDeleteDialogOpen () {
        setDeleteConfirmOpen(true)
    }

    function handleDeleteDialogClose () {
        setDeleteConfirmOpen(false)
    }

    function handleDeleteConfirm (){
        onDelete(item.id)
        setDeleteConfirmOpen(false)
    }

    function handleViewReport() {
        navigate(`/reports?interviewqaid=${item.id}`)
    }
    
    return (
        <Card
            onClick={() => onClick(item.id)}
            sx={{
                marginTop: 1,
                backgroundColor: isActive ? '#e57373' : '#ef9a9a',
                borderColor: '#3f51b5',
                borderWidth: 2,
                boxShadow: '0 8px 12px rgba(50, 50, 93, 0.25), 0 4px 6px rgba(0, 0, 0, 0.16)',
                position: 'relative',
                paddingBottom: 4,
                cursor: "pointer",
            }}>
            <CardContent>
                <Typography 
                    variant="button"
                    display="block"
                    noWrap
                    component="div"
                    sx={{
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        maxHeight: '3em',
                        marginBottom: '1em',
                        overflow: 'hidden'
                    }}
                >
                    {item.friendlyname ? item.friendlyname : "Please give this a friendly name"}
                </Typography>
                <Typography
                    variant="subtitle1"
                    align="left"
                    display="block"
                    sx={{ pt: "4px", pb : "8px"}}
                >
                    {`You have `}
                    <Typography fontWeight="bold" component="span">
                        {evaluators.length}
                    </Typography>
                    <Typography component="span">
                        {` evaluations for this story.`}
                    </Typography>
                </Typography>
            </CardContent>
            <Box sx={{ position: 'absolute', bottom: 8, right: 8 }}>
                {carddata.lockedstatus && <Lock sx={{color: "#333333"}} />}
            </Box>
            <Box sx={{ position: 'absolute', bottom: 8, left: 8 }}>
                <IconButton size="small" onClick={handleDeleteDialogOpen}>
                    <Delete sx={{color: "#333333"}} />
                </IconButton>
                <Dialog open={deleteConfirmOpen} onClose={handleDeleteDialogClose}>
                    <DialogTitle>Delete Card</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete this card and all associated data?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDeleteDialogClose}>Cancel</Button>
                        <Button onClick={handleDeleteConfirm} color="error">Delete</Button>
                    </DialogActions>
                </Dialog>
            </Box>
            {evaluators.length > 0 ?
                <Box sx={{ position: 'absolute', bottom: 8, left: 'calc(50% - 12px)' }}>
                    <IconButton size="small" onClick={handleViewReport}>
                        <Person sx={{color: "#333333"}} />({evaluators.length})
                    </IconButton>
                </Box> :
                null }
        </Card>
    )
}

export default InterviewQACard