import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import { formatDistanceToNow, differenceInSeconds, format } from 'date-fns'


import { API, graphqlOperation } from 'aws-amplify'

import { TextField, Button, Box, Dialog, DialogTitle, DialogContent, DialogActions, MenuItem, Select, InputLabel, FormControl, Avatar, Grid, Typography, Paper } from '@mui/material'

import { listLeadershipPrinciples, questionsByLpidAndId, listTargetRoles, listPersonas, reviewedPreparedAnswerbyCandidateId, getCustomerSubscription } from '../graphql/queries'
import { createReviewedPreparedAnswer, updateInterviewQA } from '../graphql/mutations'

import InterviewQAThreadContent from './InterviewQAThreadContent'
import CustomToolTip from './CustomToolTip'

function InterviewQAContent({ interviewQA, onSave, username }) {
    const [localInterviewQA, setLocalInterviewQA] = useState({ ...interviewQA })
    const [leadershipPrinciples, setLeadershipPrinciples] = useState([])
    const [lpid, setLpid] = useState()
    const [targetRoles, setTargetRoles] = useState([])
    const [interviewerPersonas, setInterviewerPersonas] = useState([])
    const [evaluateButtonActive, setEvaluateButtonActive] = useState(false)
    const [showDialog, setShowDialog] = useState(false)
    const [selectedTargetRole, setSelectedTargetRole] = useState("")
    const [selectedInterviewerPersona, setSelectedInterviewerPersona] = useState("")
    const [isSubscriptionActive, setIsSubscriptionActive] = useState(false)
    const [evaluators, setEvaluators] = useState(interviewQA.reviewedpreparedanswers.items || [])
    const [wordCount, setWordCount] = useState(0)

    const sequence2Content = localInterviewQA.interviewqathreads.items.find(t => t.sequence === 2)?.content || ''
    const navigate = useNavigate()
    const tooltipFontSize='1rem' //I really should move this out to a config file and import it for use

    const updatedAt = new Date(interviewQA.updatedAt)
    const timeDelta = differenceInSeconds(new Date(), updatedAt)
    const minWordCount = 200

    useEffect(() => {
        setLocalInterviewQA(interviewQA)
    }, [interviewQA])

    useEffect(() => {
        async function fetchLeadershipPrinciples() {
            try {
                const lpData = await API.graphql(graphqlOperation(listLeadershipPrinciples))
                const listOfLPs = lpData.data.listLeadershipPrinciples.items
                setLeadershipPrinciples(listOfLPs)
            } catch (error) {
                console.error('Error fetching Leadership Principles:', error)
            }
        }

        async function fetchTargetRoles() {
            try {
                const targetRoleData = await API.graphql(graphqlOperation(listTargetRoles))
                const listOfTargetRoles = targetRoleData.data.listTargetRoles.items
                setTargetRoles(listOfTargetRoles)
            } catch (error) {
                console.error('Error fetching Target Roles:', error)
            }
        }

        async function fetchPersonas() {
            try {
                const personaData = await API.graphql(graphqlOperation(listPersonas))
                const listOfPersonas = personaData.data.listPersonas.items
                setInterviewerPersonas(listOfPersonas)
            } catch (error) {
                console.error('Error fetching Personas:', error)
            }
        }

        checkSubscriptionStatus()
        fetchLeadershipPrinciples()
        fetchTargetRoles()
        fetchPersonas()
    }, [])

    useEffect(() => {
        const answerThread = localInterviewQA.interviewqathreads.items.find(t => t.sequence === 2)
        const wordCount = answerThread && answerThread.content.split(' ').length
        setEvaluateButtonActive(wordCount && wordCount > minWordCount)
    }, [localInterviewQA])

    async function checkSubscriptionStatus() {
        try {
            const customerSubscriptionData = await API.graphql(
                graphqlOperation(getCustomerSubscription, { id: username })
            )
            if (customerSubscriptionData.data.getCustomerSubscription){
                const subscriptionStatus = customerSubscriptionData.data.getCustomerSubscription.status
                setIsSubscriptionActive(subscriptionStatus === "active")
            }
            else {
                setIsSubscriptionActive(false)
            }

        } catch (error) {
            console.error('Error fetching customer subscription:', error)
        }
    }

    function autoGenButton() {
        const isDisabled = sequence2Content !== ''
        const button = (
            <Button
                variant="contained"
                color="primary"
                onClick={() => handleAutoGenQuestionClick()}
                disabled={isDisabled}
            >
                Genearate a Question For Me
            </Button>
        )

        if (isDisabled) {
            return (
                <CustomToolTip title="Delete the value in the answer field in order to auto-generate a new question." tooltipFontSize={tooltipFontSize}>
                    <span>{button}</span>
                </CustomToolTip>
            )
        }

        return button
    }

    function handleFriendlyNameChange(event) {
        const newFriendlyName = event.target.value
        const updatedInterviewQA = { ...localInterviewQA, friendlyname: newFriendlyName }
        setLocalInterviewQA(updatedInterviewQA)
    }

    function handleThreadContentChange(sequence, newContent) {
        setLocalInterviewQA(prevLocalInterviewQA => {
            const updatedThreads = prevLocalInterviewQA.interviewqathreads.items.map((t) => {
                if (t.sequence === sequence) {
                    return { ...t, content: newContent }
                }
                return t
            })

            const wordCount = newContent.split(' ').length
            setWordCount(wordCount)

            return { ...prevLocalInterviewQA, interviewqathreads: { items: updatedThreads } }
        })
    }

    async function handleAutoGenQuestionClick() {
        const randomLP = leadershipPrinciples[Math.floor(Math.random() * leadershipPrinciples.length)]

        try {
            const questionList = await API.graphql(
                graphqlOperation(questionsByLpidAndId, { lpid: randomLP.id })
            )

            const questions = questionList.data.questionsByLpidAndId.items
            const randomQuestion = questions[Math.floor(Math.random() * questions.length)]

            handleThreadContentChange(1, randomQuestion.questiontext)
            setLpid(randomLP.id)
        } catch (error) {
            console.error('Error fetching questions:', error)
        }
    }

    function handleSaveClick() {
        if (lpid) {
            onSave({ ...localInterviewQA, lpid: lpid })
        } else {
            onSave(localInterviewQA)
        }
    }

    function openDialog() {
        setShowDialog(true)
    }

    function closeDialog() {
        setShowDialog(false)
    }

    async function handleOkClick() {
        closeDialog()
    
        const existingAnswerData = await API.graphql(
            graphqlOperation(reviewedPreparedAnswerbyCandidateId, {
                interviewqaid: interviewQA.id
            })
        )
        
        const reviewedPreparedAnswer = {
            interviewqaid: interviewQA.id,
            targetroleid: selectedTargetRole,
            personaid: selectedInterviewerPersona
            // Additional fields can be added as needed
        }
        
        const existingAnswers = existingAnswerData.data.reviewedPreparedAnswerbyCandidateId.items
        const existingAnswer = existingAnswers.find(answer =>
            answer.interviewqaid === interviewQA.id &&
            answer.personaid === selectedInterviewerPersona &&
            answer.targetroleid === selectedTargetRole
        )
    
        if (existingAnswer) {
            alert('You already have an evaluation completed for this combination. Change the target role or interviewer persona.')
            return
        }
    
        try {
            const response = await API.graphql(graphqlOperation(createReviewedPreparedAnswer, { input: reviewedPreparedAnswer }))
            const createdReviewedAnswer = response.data.createReviewedPreparedAnswer
    
            // Redirect to Report with both interviewqaid and the new reviewedAnswerId passed as search parameters
            navigate(`/reports?interviewqaid=${interviewQA.id}&reviewedAnswerId=${createdReviewedAnswer.id}`)
        } catch (error) {
            console.error('Error creating ReviewedPreparedAnswer:', error)
        }
    }
    
    function handleQuestionChange(sequence, newContent) {
        // If the sequence is 1 (a question), set lpid to null
        if (sequence === 1) {
            setLpid(null)
        }
        handleThreadContentChange(sequence, newContent)
    }

    function openEvaluatorReport (evaluator) {
        navigate(`/reports?interviewqaid=${interviewQA.id}&reviewedAnswerId=${evaluator.id}`)
    }
    
    function renderEvaluatorAvatar(evaluator) {
        return (
            <Grid
                key={evaluator.id}
                item xs={4}
                onClick={() => openEvaluatorReport(evaluator)}
            >
                <Avatar
                    alt={evaluator.persona.role}
                    style={{
                        backgroundColor: '#f50057',
                        margin: 'auto',
                        marginBottom: '4px',
                        cursor: 'pointer',
                        transition: 'transform .2s',
                    }}
                    onMouseEnter={(e) => {
                        e.currentTarget.style.transform = 'scale(1.2)';
                    }}
                    onMouseLeave={(e) => {
                        e.currentTarget.style.transform = ''
                    }}
                />
                <Typography
                    variant="caption"
                    align="center"
                    sx={{ fontWeight: 'bold', cursor: 'pointer', whiteSpace: 'pre-wrap'}}
                    onClick={() => openEvaluatorReport(evaluator)}
                >
                    {evaluator.persona.role}
                </Typography>
                <Typography
                    variant="caption"
                    align="center"
                    sx={{ cursor: 'pointer', whiteSpace: 'pre-wrap' }}
                    onClick={() => openEvaluatorReport(evaluator)}
                >
                    {'\n'}For the role of{'\n'}{evaluator.targetrole.role}
                </Typography>
            </Grid>
        )
    }

    return (
        <div>
            <div>
                <TextField
                label="Friendly Name"
                value={localInterviewQA.friendlyname ? localInterviewQA.friendlyname : ''}
                onChange={handleFriendlyNameChange}
                InputLabelProps={{
                    style: {
                    fontSize: localInterviewQA.friendlyname ? '1.2rem' : '1rem',
                    },
                }}
                fullWidth
                />
            </div>
            <Box height="20px" />
            <div>
                {localInterviewQA.interviewqathreads.items
                .sort((a, b) => a.sequence - b.sequence)
                .map((thread, index) => (
                    <InterviewQAThreadContent
                    key={index}
                        thread={thread}
                        onBlue={handleSaveClick}
                        onChange={(value) => handleQuestionChange(thread.sequence, value)}
                    />
                ))}
            </div>
            <div>
                {timeDelta <= 240 * 60 && (
                    <Typography variant="body2" align="left" sx={{pt: "5px", pb: "40px", fontStyle: "italic"}}>
                        This story last saved at {format(updatedAt, 'hh:mm a \'on\' MM/dd/yyyy')}
                        {/* , about {formatDistanceToNow(updatedAt)} ago */}
                    </Typography>
                )}                
                    <Grid container direction="column" justifyContent="flex-end" spacing={2}>
                    <Grid item>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                gap: '10px',
                            }}
                        >
                            {isSubscriptionActive ? (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={!evaluateButtonActive}
                                    onClick={openDialog}
                                >
                                    Evaluate My Answer
                                </Button>
                            ) : (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => navigate("/profile")}
                                >
                                    Purchase to Evaluate
                                </Button>
                            )}
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSaveClick}
                            >
                                Save
                            </Button>
                            {autoGenButton()}
                        </Box>
                    </Grid>
                    {!evaluateButtonActive && (
                        <Grid item>
                            <Typography variant="body2" align="left" sx={{paddingLeft: "140px", fontStyle: "italic"}}>
                                Words needed to enable the Evalution button: {minWordCount - wordCount} (miniumum: {minWordCount})
                            </Typography>
                        </Grid>
                    )}
                </Grid>
                {evaluators.length > 0 ? (
                <Paper elevation={1} sx={{ padding: '10px', marginBottom: '16px', marginTop: '20px', border: '2px solid'}}>
                    <Typography variant="h6" align="left" display="block" sx={{  marginBottom: '55px'}}>
                        You have had this story evaluated by:
                    </Typography>
                    <Grid container spacing={1} sx={{ marginBottom: '16px' }}>
                        {evaluators.map((evaluator) => {
                            return renderEvaluatorAvatar(evaluator);
                        })}
                    </Grid>
                </Paper>
                ) : '' }
            </div>
            <Dialog open={showDialog} onClose={closeDialog}>
                <DialogTitle>Evaluation Setup</DialogTitle>
                <DialogContent>
                    <p>In order to evaluate your answer, you need to provide a role for the job you are seeking, and you should select the interviewer you want to evaluate your answer.</p>
                    <FormControl fullWidth>
                        <InputLabel id="target-role-label">Target Role</InputLabel>
                        <Select
                            labelId="target-role-label"
                            value={selectedTargetRole}
                            sx = {{ mt: "6px", mb: "6px"}}
                            onChange={(event) => setSelectedTargetRole(event.target.value)}
                        >
                            {targetRoles.map((role) => (
                                <MenuItem key={role.id} value={role.id}>{role.role}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id="interviewer-persona-label">Interviewer Persona</InputLabel>
                        <Select
                            labelId="interviewer-persona-label"
                            value={selectedInterviewerPersona}
                            sx = {{ mt: "6px", mb: "6px"}}
                            onChange={(event) => setSelectedInterviewerPersona(event.target.value)}
                        >
                            {interviewerPersonas
                                .sort((a, b) => a.role.localeCompare(b.role))
                                .map((persona) => (
                                    <MenuItem key={persona.id} value={persona.id}>{persona.role}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog}>Cancel</Button>
                    <Button onClick={handleOkClick}>OK</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default InterviewQAContent